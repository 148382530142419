import React, {
    type FC,
    type LazyExoticComponent,
    type ReactElement,
    Suspense,
    lazy,
    memo,
    startTransition,
    useCallback,
    useMemo,
    useState
} from "react"

import classNames from "classnames"
import { type Location, NavLink, type NavigateFunction, useLocation, useNavigate } from "react-router-dom"

import Heading from "common/components/Heading/Heading"
import useOnRouteChange from "common/hooks/use-route-change"
import { type IUseTranslation, useTranslation } from "common/hooks/use-translation"
import type { TTFunction } from "common/i18n/i18n"
import { Option } from "common/models/option"
import { isEmptyArray } from "common/utils/gates"

import { CoachUrls } from "main-app/constants"
import { useAuthContext } from "main-app/context/Auth"
import { CoachCalendarStepUrls } from "main-app/pages/coach-settings/constants"
import Button from "main-app/shared/button/Button"
import AttentionNotificationBox from "main-app/shared/notification/AttentionsNotificationBox"
import Select from "main-app/shared/select-default/Select"
import Spinner from "main-app/shared/spinner/Spinner"
import type { TEmptyCallback } from "main-app/shared/types/functions"
import useRoles from "main-app/utils/hooks/use-roles"

import { type TCoachSessionWithoutAttendanceMarksQuery, useCoachSessionsWithoutAttendanceMarksQuery } from "../api"

import { type TCoachMarkAttendanceBoxProps as TMarkAttendanceBoxProps } from "./CoachMarkAttendanceBox"

const MarkAttendanceBox: LazyExoticComponent<FC<TMarkAttendanceBoxProps>> = lazy(
    (): Promise<{ default: FC<TMarkAttendanceBoxProps> }> => import("./CoachMarkAttendanceBox")
)

const MarkAttendanceBoxFallbackSpinner: FC = (): ReactElement => (
    <div className="mx-auto my-3 w-min">
        <Spinner />
    </div>
)

interface IProps {
    selectedPeriod: string
    onSearch: (value: string) => void
    onClickSetupCalendar: (value: boolean) => void
    onSelectSessionPeriod: (value: string) => void
    handleOpenAttendanceModal(id: number): void
    getShouldSelectBeVisible(location: Location): boolean
}

const sessionsPeriods = [
    new Option({ label: "Upcoming Sessions", value: "upcoming_sessions" }),
    new Option({ label: "Past Sessions", value: "past_sessions" })
]

enum EAttentionNotificationBoxType {
    SetUpCalendar,
    RelinkCalendar
}

interface IAttentionNotificationBoxConfig {
    isVisible: boolean
    withTitleUppercased?: boolean
    title: string
    description: string
    actionLabel: string
    action: TEmptyCallback
}

type TAttentionBoxesConfig = {
    [K in EAttentionNotificationBoxType]: IAttentionNotificationBoxConfig
}

type TGetIsAttentionBoxVisibleFunction = (type: EAttentionNotificationBoxType) => boolean

type TAttentionBoxesHandlers = { [K in EAttentionNotificationBoxType]: TEmptyCallback }

function renderAttentionBoxConfig({
    getIsBoxVisible,
    action,
    t
}: {
    getIsBoxVisible(type: EAttentionNotificationBoxType): boolean
    action(type: EAttentionNotificationBoxType): void
    t: TTFunction
}): TAttentionBoxesConfig {
    return {
        [EAttentionNotificationBoxType.SetUpCalendar]: {
            isVisible: getIsBoxVisible(EAttentionNotificationBoxType.SetUpCalendar),
            title: t("coachSide.dashboard.attentionBox.setupCalendar.title"),
            description: t("coachSide.dashboard.attentionBox.setupCalendar.description"),
            actionLabel: t("coachSide.dashboard.attentionBox.setupCalendar.action"),
            action: (): void => startTransition((): void => action(EAttentionNotificationBoxType.SetUpCalendar))
        },
        [EAttentionNotificationBoxType.RelinkCalendar]: {
            isVisible: getIsBoxVisible(EAttentionNotificationBoxType.RelinkCalendar),
            withTitleUppercased: true,
            title: t("coachSide.dashboard.attentionBox.relinkAccounts.title"),
            description: t("coachSide.dashboard.attentionBox.relinkAccounts.description"),
            actionLabel: t("coachSide.dashboard.attentionBox.relinkAccounts.action"),
            action: (): void => startTransition((): void => action(EAttentionNotificationBoxType.RelinkCalendar))
        }
    }
}

const Header: React.FC<IProps> = ({
    selectedPeriod,
    onSearch,
    getShouldSelectBeVisible,
    onSelectSessionPeriod,
    onClickSetupCalendar,
    handleOpenAttendanceModal
}: IProps) => {
    const { t }: IUseTranslation = useTranslation()
    const { user } = useAuthContext()
    const { isCoach } = useRoles()
    const [searchValue, setSearchValue] = useState("")
    const location: Location = useLocation()

    const isSessionsPage: boolean = getShouldSelectBeVisible(location)

    const {
        data: sessionsWithoutAttendanceMarkData,
        isFetching: isSessionsWithoutAttendanceMarkDataFetching
    }: TCoachSessionWithoutAttendanceMarksQuery = useCoachSessionsWithoutAttendanceMarksQuery()

    const sessionPeriodIndex = useMemo(() => {
        return sessionsPeriods?.findIndex(option => option.value === selectedPeriod)
    }, [selectedPeriod])

    useOnRouteChange(
        useCallback(() => {
            setSearchValue("")
            onSearch("")
        }, [onSearch])
    )

    const handleSearch = (value: string) => {
        setSearchValue(value)
        if (!value) {
            onSearch(value)
        }
        if (value.length >= 3) {
            onSearch(value)
        }
    }

    const onSelectPeriod = (period: Option) => {
        startTransition(() => onSelectSessionPeriod(period.value as string))
    }

    const navigate: NavigateFunction = useNavigate()

    const getIsAttentionBoxVisible: TGetIsAttentionBoxVisibleFunction = useCallback(
        (type: EAttentionNotificationBoxType): boolean => {
            switch (type) {
                case EAttentionNotificationBoxType.SetUpCalendar:
                    return !user?.calendar_setup_confirmed && isCoach
                case EAttentionNotificationBoxType.RelinkCalendar:
                    return user?.accounts_relinking_required && isCoach
                default:
                    return false
            }
        },
        [user?.calendar_setup_confirmed, user?.accounts_relinking_required, isCoach]
    )

    const attentionBoxConfigHandlers: TAttentionBoxesHandlers = useMemo(
        (): TAttentionBoxesHandlers => ({
            [EAttentionNotificationBoxType.RelinkCalendar]: () => navigate(CoachCalendarStepUrls.RELINK_ACCOUNTS),
            [EAttentionNotificationBoxType.SetUpCalendar]: () => onClickSetupCalendar(true)
        }),
        [navigate, onClickSetupCalendar]
    )

    const attentionBoxConfigToRender: TAttentionBoxesConfig = useMemo(
        (): TAttentionBoxesConfig =>
            renderAttentionBoxConfig({
                action: (type: EAttentionNotificationBoxType): void => attentionBoxConfigHandlers[type](),
                getIsBoxVisible: getIsAttentionBoxVisible,
                t
            }),
        [t, attentionBoxConfigHandlers, getIsAttentionBoxVisible]
    )

    function renderMarkAttendanceBox(): ReactElement {
        return isSessionsWithoutAttendanceMarkDataFetching ? (
            <div className="mx-auto my-3 w-min">
                <MarkAttendanceBoxFallbackSpinner />
            </div>
        ) : isEmptyArray(sessionsWithoutAttendanceMarkData) ? null : (
            <MarkAttendanceBox
                sessions={sessionsWithoutAttendanceMarkData}
                handleOpenAttendanceModal={handleOpenAttendanceModal}
            />
        )
    }

    return (
        <div className="mb-60" data-testid="coach-table-header">
            <div className={classNames("row", { "mb-50": user?.calendar_setup_confirmed || !isCoach })}>
                <div className="col-12 col-lg-5">
                    <Heading>{t("coachSide.dashboard.heading")}</Heading>
                </div>
                <div className="col-12 col-lg-7">
                    <div className="form-group mb-0">
                        <input
                            type="text"
                            className="form-control"
                            placeholder={t("Search")}
                            data-testid="search-input"
                            value={searchValue}
                            onChange={e => handleSearch(e.target.value)}
                        />
                    </div>
                </div>
            </div>
            <div className="mb-40 mt-60">
                {Object.values(attentionBoxConfigToRender)?.map((c: IAttentionNotificationBoxConfig, index: number) => (
                    <AttentionNotificationBox
                        key={`attention-box-${c.actionLabel}`}
                        className={classNames("align-items-center justify-content-center", {
                            "d-flex": c.isVisible,
                            "d-none": !c.isVisible,
                            "mb-1": index !== Object.values(attentionBoxConfigToRender)?.length - 1
                        })}>
                        <div>
                            <span className="color-gray font-extrabold">
                                {c.withTitleUppercased ? c.title.toUpperCase() : c.title}
                            </span>
                            &nbsp;
                            {c.description}
                            &nbsp;
                            <Button className="p-0 m-0" variant="default" onClick={c.action}>
                                <span className="color-brand font-extrabold">{c.actionLabel}</span>
                            </Button>
                        </div>
                    </AttentionNotificationBox>
                ))}
            </div>

            <Suspense fallback={<MarkAttendanceBoxFallbackSpinner />}>{renderMarkAttendanceBox()}</Suspense>

            <div className="coach-main__tab-list">
                <div className="d-flex align-items-center h-100">
                    <NavLink
                        to={CoachUrls.SESSIONS_LIST}
                        className={({ isActive }) => classNames("tab-link", { active: isActive })}>
                        Sessions View
                    </NavLink>
                    <NavLink
                        to={CoachUrls.PARTICIPANTS_LIST}
                        className={({ isActive }) => classNames("tab-link", { active: isActive })}>
                        Participants View
                    </NavLink>
                    <NavLink
                        to={CoachUrls.GROUP_LIST}
                        className={({ isActive }) => classNames("tab-link", { active: isActive })}>
                        Groups View
                    </NavLink>
                </div>
                {isSessionsPage && (
                    <div className="form-group d-flex mb-0 position-relative col-4">
                        <label htmlFor="cohort" className="font-extrabold align-self-center mr-3 mb-0">
                            Viewing
                        </label>
                        <Select
                            optionsList={sessionsPeriods}
                            onSelect={onSelectPeriod}
                            defaultOptionIndex={sessionPeriodIndex}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

const CoachDashboardHeader = memo(Header)

export { CoachDashboardHeader }
