import type { TTFunction } from "common/i18n/i18n"

import { emptyAsyncCallback } from "main-app/shared/types/functions"

import { ESurveyStatus, IParticipantSurveyModel } from "../api"

import type { TSurveysDataListItemProps } from "./SurveysDataListItem"

function getHeadingListItem(t: TTFunction): TSurveysDataListItemProps {
    // don't want to use <table />
    return {
        id: 0,
        title: t("participantSide.surveysPage.surveysList.header.title"),
        status: t("participantSide.surveysPage.surveysList.header.status"),
        closeDate: t("participantSide.surveysPage.surveysList.header.closeDate"),
        accessCode: String(),
        handleClick: emptyAsyncCallback,
        tabIndex: undefined
    } as TSurveysDataListItemProps
}

function getIsAttentionBoxVisible(list: IParticipantSurveyModel[] | []): boolean {
    return list?.some(({ status }: IParticipantSurveyModel) => status === ESurveyStatus.InCompleted)
}

type TUtils = {
    getHeadingListItem(t: TTFunction): TSurveysDataListItemProps
    getIsAttentionBoxVisible(list: IParticipantSurveyModel[] | []): boolean
}

const utils: TUtils = { getHeadingListItem, getIsAttentionBoxVisible }

export { utils as surveysPageUiUtils }
