import React, {
    type Context,
    type Dispatch,
    type FC,
    type MutableRefObject,
    type ReactElement,
    type ReactNode,
    type SetStateAction,
    createContext,
    useCallback,
    useContext,
    useMemo,
    useRef,
    useState
} from "react"

import { QueryClient, useQueryClient } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { type NavigateFunction, useNavigate } from "react-router-dom"

import { HTTPStatus } from "common/constants"
import { isEmptyString } from "common/utils/gates"
import { setUserSentry } from "common/utils/set-user-sentry"
import { setUserDataDog } from "common/utils/setuser-datadog"

import { type TLogoutMutation, useLogoutMutation } from "main-app/api/mutations/use-logout-mutation"
import { type TMeQuery, useMeQuery } from "main-app/api/use-me-query"
import { useSocialCompleteQuery } from "main-app/api/use-social-complete-query"
import { AuthUrls, INDEX_URL, ServerStateKeys } from "main-app/constants"
import type { IJwtTokenPairModel } from "main-app/models/jwt"
import User from "main-app/models/user"
import {
    ELocalStorageServiceItemKey,
    ESessionStorageServiceItemKey,
    EStorageServiceType,
    StorageService
} from "main-app/services"
import { type TEmptyAsyncCallback, emptyCallback } from "main-app/shared/types/functions"

import { IStoreContext, useStoreContext } from "./GlobalStore"

type TProps = { children: ReactNode }

type TAuthContextType = {
    user: User
    isLoading: boolean
}
type TAuthContextActionType = {
    setUser(usr: User): void
    logout: TEmptyAsyncCallback
    refetchUser: TEmptyAsyncCallback
}

const AuthContext: Context<TAuthContextType> = createContext<TAuthContextType>(null)

const AuthContextActions: Context<TAuthContextActionType> = createContext<TAuthContextActionType>(null)

const localStorageService: StorageService = new StorageService(EStorageServiceType.Local)
const sessionStorageService: StorageService = new StorageService(EStorageServiceType.Session)

const AuthContextProvider: FC<TProps> = ({ children }: TProps): ReactElement => {
    const [user, _setUser]: [User, Dispatch<SetStateAction<User>>] = useState<User>(null)
    const { resetState }: IStoreContext = useStoreContext()
    const queryClient: QueryClient = useQueryClient()

    const jwtAuthUrl: MutableRefObject<string> = useRef<string>(JSAPP_CONF.jwt_pair_url ?? String())

    useSocialCompleteQuery({
        enabled: !isEmptyString(jwtAuthUrl.current),
        onSuccess: (data: IJwtTokenPairModel): void => (
            localStorageService.setItem(ELocalStorageServiceItemKey.TokenAccess, data.access),
            localStorageService.setItem(ELocalStorageServiceItemKey.TokenRefresh, data.refresh),
            localStorageService.setItem(ELocalStorageServiceItemKey.SocialComplete, true)
        )
    })

    const handleUserSet: (data: User) => void = useCallback(
        (data: User): void => (
            sessionStorageService.removeItem(ESessionStorageServiceItemKey.UserLogout),
            localStorageService.setItem(ELocalStorageServiceItemKey.TokenAccess, data.jwtPair.access),
            localStorageService.setItem(ELocalStorageServiceItemKey.TokenRefresh, data.jwtPair.refresh),
            _setUser(data)
        ),
        []
    )

    const {
        isFetching: isMeDataFetching,
        isRefetching: isMeDataRefetching,
        refetch: handleMeDataRefetch
    }: TMeQuery = useMeQuery({
        enabled: Boolean(!user && localStorageService.getItem(ELocalStorageServiceItemKey.TokenAccess)),
        onSuccess: (data: User): void => (
            setUserSentry(data),
            setUserDataDog(data),
            queryClient.setQueryData([ServerStateKeys.User], () => data),
            sessionStorageService.removeItem(ESessionStorageServiceItemKey.UserLogout),
            localStorageService.setItem(ELocalStorageServiceItemKey.TokenAccess, data.jwtPair.access),
            localStorageService.setItem(ELocalStorageServiceItemKey.TokenRefresh, data.jwtPair.refresh),
            _setUser(data)
        ),
        onError: (error: AxiosError): void =>
            error?.response?.status === HTTPStatus.UNAUTHORIZED ? _setUser(null) : emptyCallback()
    })

    const handleUserRefetch: TEmptyAsyncCallback = useCallback(
        async (): Promise<void> => (await handleMeDataRefetch(), void 0),
        [handleMeDataRefetch]
    )

    const handleLogoutMutation: TLogoutMutation = useLogoutMutation()

    const navigate: NavigateFunction = useNavigate()

    const handleLogout: TEmptyAsyncCallback = useCallback(
        async (): Promise<void> => (
            await handleLogoutMutation.mutateAsync(
                { refresh: localStorageService.getItem(ELocalStorageServiceItemKey.TokenRefresh) },
                {
                    onSuccess: (): void => (
                        setUserSentry(null), _setUser(null), resetState(), navigate(INDEX_URL), queryClient.clear()
                    ),
                    onError: (error: AxiosError) =>
                        error?.response?.status === HTTPStatus.UNAUTHORIZED && navigate(AuthUrls.LOGIN),
                    onSettled: async () => (
                        await queryClient.cancelQueries({ type: "all" }, { silent: true }),
                        await queryClient.invalidateQueries({ type: "all" }),
                        await queryClient.resetQueries({ type: "all" }),
                        localStorageService.clearStorage(),
                        sessionStorageService.setItem(ESessionStorageServiceItemKey.UserLogout, true),
                        navigate(AuthUrls.LOGIN)
                    )
                }
            ),
            void 0
        ),
        [handleLogoutMutation, resetState, queryClient, navigate]
    )

    const values: TAuthContextType = useMemo(
        (): TAuthContextType => ({
            user,
            isLoading: isMeDataFetching || isMeDataRefetching
        }),
        [user, isMeDataFetching, isMeDataRefetching]
    )

    const actions: TAuthContextActionType = useMemo(
        (): TAuthContextActionType => ({
            logout: handleLogout,
            setUser: handleUserSet,
            refetchUser: handleUserRefetch
        }),
        [handleLogout, handleUserRefetch, handleUserSet]
    )

    return (
        <AuthContext.Provider value={values}>
            <AuthContextActions.Provider value={actions}>{children}</AuthContextActions.Provider>
        </AuthContext.Provider>
    )
}

interface IAuthContext extends TAuthContextType, TAuthContextActionType {}

function useAuthContext(): ReturnType<() => IAuthContext> {
    const auth: TAuthContextType = useContext(AuthContext)
    const actions: TAuthContextActionType = useContext(AuthContextActions)

    if (!auth || !actions) {
        throw new Error("useAuthContext can only be used inside AuthProvider")
    }

    return {
        ...auth,
        ...actions
    }
}

export { AuthContext, AuthContextActions, useAuthContext, type IAuthContext, AuthContextProvider as default }
