import React, { type FC, type ReactElement } from "react"

import classNames from "classnames"

import { type IUseTranslation, type TTranslationKeys, useTranslation } from "common/hooks/use-translation"
import { isString } from "common/utils/gates"

import { ESurveyStatus } from "../api"

type TProps = { status: ESurveyStatus | string }

const statusTranslationsMap: { [S in ESurveyStatus]: keyof TTranslationKeys } = {
    [ESurveyStatus.Completed]: "participantSide.surveysPage.surveysList.status.completed",
    [ESurveyStatus.InCompleted]: "participantSide.surveysPage.surveysList.status.inCompleted",
    [ESurveyStatus.Expired]: "participantSide.surveysPage.surveysList.status.expired"
}

const statusBackgroundsMap: { [S in ESurveyStatus]: string } = {
    [ESurveyStatus.Completed]: "completed",
    [ESurveyStatus.InCompleted]: "incompleted",
    [ESurveyStatus.Expired]: "expired"
}

const Badge: FC<TProps> = ({ status }: TProps): ReactElement => {
    const { t }: IUseTranslation = useTranslation()

    return (
        <div
            className={classNames(
                "surveys__list__item__status",
                isString(status) ? "" : { [statusBackgroundsMap[status]]: [statusTranslationsMap[status]] }
            )}>
            <span className={classNames({ "font-italic color-gray": status === ESurveyStatus.Expired })}>
                {isString(status) ? status : t(statusTranslationsMap[status])}
            </span>
        </div>
    )
}

export { Badge as SurveysDataListItemStatusBadge }
