import React, {
    type Dispatch,
    type FC,
    type ReactElement,
    type SetStateAction,
    startTransition,
    useCallback,
    useEffect,
    useState
} from "react"

import { QueryClient, useQueryClient } from "@tanstack/react-query"
import classNames from "classnames"
import { type NavigateFunction, useNavigate } from "react-router-dom"

import { type IUseTranslation, useTranslation } from "common/hooks/use-translation"

import { fetchAvailableGuestSessions } from "main-app/api/use-available-guest-sessions-query"
import { ServerStateKeys } from "main-app/constants"
import Button from "main-app/shared/button/Button"
import type { TEmptyAsyncCallback, TEmptyCallback } from "main-app/shared/types/functions"

import { shouldSwapSessionOpenerBeDisplayedBasedOnOriginalSessionTime } from "../utils"

type TGuestParticipantSwapSessionOpenerProps = { sessionTime: string; sessionId: number; shouldBeHidden: boolean }

const GuestParticipantSwapSessionOpener: FC<TGuestParticipantSwapSessionOpenerProps> = ({
    sessionTime,
    sessionId,
    shouldBeHidden
}: TGuestParticipantSwapSessionOpenerProps): ReactElement => {
    const { t }: IUseTranslation = useTranslation()

    const navigate: NavigateFunction = useNavigate()

    const queryClient: QueryClient = useQueryClient()

    const handleOpenerClick: TEmptyAsyncCallback = useCallback(
        async (): Promise<void> => (
            await queryClient.prefetchQuery([ServerStateKeys.AvailableGuestSessions, fetchAvailableGuestSessions]),
            navigate(`/session-swapping/${sessionId}`)
        ),
        [navigate, queryClient, sessionId]
    )

    const [shouldOpenerBeVisibleBasedOnSessionDate, setShouldOpenerBeVisibleBasedOnSessionDate]: [
        boolean,
        Dispatch<SetStateAction<boolean>>
    ] = useState<boolean>(shouldSwapSessionOpenerBeDisplayedBasedOnOriginalSessionTime(sessionTime))

    const handleOpenerVisibility: TEmptyCallback = (): void => {
        startTransition((): void =>
            setShouldOpenerBeVisibleBasedOnSessionDate(
                shouldSwapSessionOpenerBeDisplayedBasedOnOriginalSessionTime(sessionTime)
            )
        )
    }

    useEffect(
        () => (
            setInterval(handleOpenerVisibility, 1000), () => clearInterval(setInterval(handleOpenerVisibility, 1000))
        )
    )

    return (
        <Button
            variant="outline-secondary"
            onClick={handleOpenerClick}
            className={classNames("swap-session__opener", {
                hidden: shouldBeHidden || !shouldOpenerBeVisibleBasedOnSessionDate
            })}>
            {t("participantSide.coachingSessionsScreen.swapSessionOpener")}
        </Button>
    )
}

export { GuestParticipantSwapSessionOpener, type TGuestParticipantSwapSessionOpenerProps }
