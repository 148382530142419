import React, { useEffect, useMemo } from "react"

import { DateTime } from "luxon"

import CronofyWidget from "common/components/CronofyWidgets/CronofyWidget"
import { CRONOFY_WIDGET } from "common/constants"
import { isNullOrUndefined } from "common/utils/gates"

import { ThreeWay, ThreeWayManager } from "main-app/entities/three-way"
import Coach from "main-app/models/coach"
import { Session } from "main-app/models/session"
import OwlLoader from "main-app/shared/spinner/OwlLoader"
import { type TEmptyCallback, emptyCallback } from "main-app/shared/types/functions"

import CoachTimeBooked from "./CoachTimeBooked"
import CoachTimeError from "./CoachTimeError"
import { BookedTime } from "./types"

type Props = {
    isLoading: boolean
    data: any
    error: string | null
    bookedTime: BookedTime | null
    coach: Coach
    showBackBtn: boolean
    showManagerInfoBlock?: boolean
    session?: any
    allUpcomingSessions?: Session[]
    sessionId: number
    onTimePick: (notification) => void
    onClickBack: () => void
    onCloseModal: () => void
}

const WIDGET_ID: string = "time-picker-widget-cronofy"

const CRONOFY_MARKED_UPCOMING_DAY_BORDER_COLOR: string = "#7ED321"
const CRONOFY_MARKED_UPCOMING_DAY_BACKGROUND_COLOR: string = "#DEF2C8"

const CoachTimeScheduleContainer = ({
    isLoading,
    data,
    error,
    bookedTime,
    coach,
    showBackBtn,
    showManagerInfoBlock = false,
    session,
    allUpcomingSessions = [],
    sessionId,
    onTimePick,
    onCloseModal,
    onClickBack
}: Props) => {
    useEffect((): TEmptyCallback => {
        if (!allUpcomingSessions?.length) return emptyCallback

        // the point of this whole hook is to mark slightly the days when user already have sessions.
        // no way to do this via cronofy itself, that's why there is a need to do it manually

        const timeout = setTimeout(
            (): void => (
                document
                    ?.getElementById(WIDGET_ID)
                    ?.querySelectorAll("td")
                    ?.forEach(
                        async (cell: HTMLTableCellElement): Promise<void> => (
                            allUpcomingSessions
                                ?.filter(async (s: Session): Promise<boolean> => !isNullOrUndefined(s?.session_time))
                                ?.map((s: Session): string => DateTime.fromISO(s?.session_time)?.day?.toString())
                                ?.includes(cell?.textContent) &&
                                cell
                                    ?.querySelector("button")
                                    ?.setAttribute(
                                        "style",
                                        `border-color: ${CRONOFY_MARKED_UPCOMING_DAY_BORDER_COLOR} !important; font-weight: 800; background-color: ${CRONOFY_MARKED_UPCOMING_DAY_BACKGROUND_COLOR};`
                                    ),
                            void 0
                        )
                    ),
                void 0
            ),
            1000 // coronofy makes an api request to catch available slots and there might be a lot of them + we can't handle this api call at all coz it's external;
            // that's why there is an optimistic updated and somekind of debounce
        )

        return (): void => clearTimeout(timeout)
    }, [allUpcomingSessions])

    const threeWayManager = useMemo(() => {
        if (!isNullOrUndefined(session?.three_way_session_manager)) {
            return new ThreeWayManager(session?.three_way_session_manager)
        }

        if (session?.extra_parties_info?.length) {
            return new ThreeWayManager(session?.extra_parties_info[0])
        }

        return null
    }, [session])

    if (isLoading) {
        return (
            <div className="p-5 coach-time-loader  d-flex align-items-center justify-content-center">
                <OwlLoader />
            </div>
        )
    }
    if (error) {
        return <CoachTimeError error={error} onClickBack={onClickBack} showBackBtn={showBackBtn} />
    }
    if (bookedTime) {
        return <CoachTimeBooked bookedTime={bookedTime} coach={coach} onClose={onCloseModal} />
    }

    return (
        <div className="book-session-box">
            {showManagerInfoBlock && (
                <ThreeWay threeWayManager={threeWayManager} sessionId={sessionId} className="mb-40" />
            )}
            {data ? (
                <CronofyWidget
                    widget={CRONOFY_WIDGET.DateTimePicker}
                    id={WIDGET_ID}
                    options={{
                        ...data,
                        callback: notification => onTimePick(notification),
                        styles: {
                            prefix: "TPC"
                        }
                    }}
                />
            ) : null}
        </div>
    )
}

export default CoachTimeScheduleContainer
