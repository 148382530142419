import { http } from "common/http"
import { getErrorMessages } from "common/utils/get-error-messages"

import { type TEmptyAsyncCallback } from "main-app/shared/types/functions"

import Urls from "../urls"

async function fetcher(): Promise<void> {
    await http.get(Urls.ping())
}

export const makePing: TEmptyAsyncCallback = async (): Promise<void> => {
    try {
        await fetcher()
    } catch (e) {
        console.log(getErrorMessages(e))
    }
}
