import { useCallback } from "react"

import { type UseQueryOptions, type UseQueryResult, useQuery } from "@tanstack/react-query"
import { AxiosError, type AxiosResponse } from "axios"

import { http } from "common/http"

import { ServerStateKeys } from "main-app/constants"
import type { IJwtTokenPairDto, IJwtTokenPairModel } from "main-app/models/jwt"

import Urls from "./urls"

type TSocialCompleteQuery = UseQueryResult<IJwtTokenPairModel, AxiosError>
type TSocialCompleteQueryParams = UseQueryOptions

type TUseSocialCompleteQueryReturn = ReturnType<(params: TSocialCompleteQueryParams) => TSocialCompleteQuery>

function socialCompleteAdapter(dto: IJwtTokenPairDto): IJwtTokenPairModel {
    return dto
}

async function socialCompleteFetcher(): Promise<IJwtTokenPairDto> {
    const { data }: AxiosResponse<IJwtTokenPairDto> = await http.get(Urls.getSocialComplete())

    return data
}

function useSocialCompleteQuery(params: TSocialCompleteQueryParams = {}): TUseSocialCompleteQueryReturn {
    return useQuery<IJwtTokenPairDto, AxiosError, IJwtTokenPairModel>(
        [ServerStateKeys.JwtSocialCompete],
        useCallback(socialCompleteFetcher, []),
        {
            // @ts-expect-error no error here, just react-query types mess
            select: useCallback(socialCompleteAdapter, []),
            ...params
        }
    )
}

export { useSocialCompleteQuery, type TSocialCompleteQuery }
