import { useCallback } from "react"

import { type UseMutationResult, useMutation } from "@tanstack/react-query"
import type { AxiosError, AxiosResponse } from "axios"

import { http } from "common/http"

import { ApiMutationKey } from "main-app/constants"
import { CoachApi as ICoachDto } from "main-app/models/coach"
import { IParticipantBase as IParticipantDto } from "main-app/models/participants"

import Urls from "../urls"

type IProgramManagerDto = ICoachDto
type TLoginMutationDto = IParticipantDto | ICoachDto | IProgramManagerDto

type TLoginMutationParams = {
    email: string
    password: string
}
type TLoginMutation = UseMutationResult<AxiosResponse<TLoginMutationDto>, AxiosError, TLoginMutationParams>

type TUseLoginMutationReturn = ReturnType<() => TLoginMutation>

async function handleLogin(params: TLoginMutationParams): Promise<AxiosResponse<TLoginMutationDto>> {
    return http.post(Urls.postLogin(), params)
}

function useLoginMutation(): TUseLoginMutationReturn {
    return useMutation<AxiosResponse<TLoginMutationDto>, AxiosError, TLoginMutationParams>(
        [ApiMutationKey.LoginCreate],
        useCallback(handleLogin, [])
    )
}

export { useLoginMutation, type TLoginMutation, type TLoginMutationDto, type TLoginMutationParams }
