import type { AxiosResponse } from "axios"
import { DateTime } from "luxon"

import { http } from "common/http"

import Urls from "main-app/api/urls"
import { DATE_FORMAT } from "main-app/constants"

import {
    ESurveyStatus,
    type IParticipantSurveyDto,
    type IParticipantSurveyModel,
    type ISurveysDto,
    type ISurveysModel,
    type TParticipantSurveyStatusDto
} from "./types"

function getSurveyEnumKey(status: TParticipantSurveyStatusDto): ESurveyStatus | string {
    switch (status.toLowerCase()) {
        case "completed":
            return ESurveyStatus.Completed
        case "incomplete":
            return ESurveyStatus.InCompleted
        case "expired":
            return ESurveyStatus.Expired
        default:
            return status
    }
}

function adaptSurveysList({ count, next, previous, results }: ISurveysDto): ISurveysModel {
    return {
        count,
        next,
        previous,
        results: results?.map(
            ({ id, title, status, close_date, access_code }: IParticipantSurveyDto): IParticipantSurveyModel => ({
                id,
                title: title ?? String(),
                status: getSurveyEnumKey(status) as ESurveyStatus,
                closeDate: DateTime.fromISO(close_date).toFormat(DATE_FORMAT.SHORT_DAY_SHORT_MONTH),
                accessCode: access_code
            })
        )
    }
}

async function fetchSurveysList(): Promise<ISurveysDto> {
    const { data }: AxiosResponse<ISurveysDto> = await http.get<ISurveysDto>(Urls.getSurveyList())

    return data
}

type TSurveysApiUtils = {
    adaptSurveysList(dto: ISurveysDto): ISurveysModel
    fetchSurveysList(): Promise<ISurveysDto>
}

const surveysApiUtils: TSurveysApiUtils = { adaptSurveysList, fetchSurveysList }

export { surveysApiUtils, type TSurveysApiUtils }
