import React, { type FC, type ReactElement, Suspense, memo } from "react"

import classNames from "classnames"

import { WarningMessage } from "main-app/components/onboarding/components/WarningMessage"
import { Participant } from "main-app/models/participants"
import { Session } from "main-app/models/session"

import Pagination from "../Pagination"
import { TableLoader } from "../TableLoader"
import { ECoachTableHeaderName, type TCoachTableConfig } from "../types"

import { CoachTableBody } from "./CoachTableBody"
import { CoachTableHeader as Header } from "./CoachTableHeader"

type TCoachTableProps = {
    dataSource: (Session | Participant)[]
    currentPaginationPage: number
    handlePageChange(page: number): void
    totalPaginationCount?: number
    isLoading?: boolean
    error?: null | string
    errorStatusText?: string
    onSort?: (order: boolean, name?: ECoachTableHeaderName) => void
    testId: string
} & TCoachTableConfig

const Table: FC<TCoachTableProps> = ({
    dataSource,
    columnsBodies,
    columnHeaders,
    tableType,
    currentPaginationPage,
    handlePageChange,
    totalPaginationCount,
    isLoading,
    onSort,
    testId,
    error,
    errorStatusText
}: TCoachTableProps): ReactElement => (
    <Suspense fallback={<TableLoader loading={isLoading} />}>
        <div className="table-wrapper" data-testid={testId}>
            <div className={classNames("table-loader", { hidden: !isLoading })} data-testid="loader">
                <div className="d-flex justify-content-center align-items-center w-100 h-100">
                    <div className="table-spinner" />
                </div>
            </div>
            <div
                className={classNames("table-results-text", {
                    active: error || errorStatusText || isLoading
                })}>
                {error ? <WarningMessage message={error} /> : errorStatusText}
            </div>
            <table className="table table-no-head-mobile valign-middle text-left text-lg-center">
                <Header onSort={onSort} columnHeaders={columnHeaders} />
                <CoachTableBody dataSource={dataSource as []} type={tableType} columnsBodies={columnsBodies} />
            </table>
        </div>
        <Pagination
            className="pagination-bar mt-50"
            currentPage={currentPaginationPage}
            totalCount={totalPaginationCount}
            onPageChange={handlePageChange}
        />
    </Suspense>
)

const CoachTable = memo(Table)

export { CoachTable, type TCoachTableProps }
