import React, { useCallback, useMemo, useState } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import classNames from "classnames"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import Heading from "common/components/Heading/Heading"
import { Option } from "common/models/option"
import { getErrorMessages } from "common/utils/get-error-messages"

import useChangeProfile from "main-app/api/mutations/use-change-profile"
import { JS_CONF } from "main-app/constants"
import { type IAuthContext, useAuthContext } from "main-app/context/Auth"
import ParticipantWrapper from "main-app/elements/participant-wrapper"
import ParticipantLayout from "main-app/layouts/ParticipantLayout"
import Button from "main-app/shared/button/Button"
import TextInput from "main-app/shared/input/TextInput"
import ProfilePhotoHandler from "main-app/shared/profile-photo-handler/ProfilePhotoHandler"
import Select from "main-app/shared/select-default/Select"
import LockSvgGray from "main-app/svgs/LockSvgGray"
import { getLanguageOptions } from "main-app/utils/common"

import LeftSidebar from "../dashboard/LeftSidebar"
import { WarningMessage } from "../onboarding/components/WarningMessage"
import { profileSetupValidation } from "../onboarding/validation/profile-setup-validation"

import "./styles.scss"

const Profile = () => {
    const { t } = useTranslation()
    const { user }: IAuthContext = useAuthContext()
    const submitProfileChanges = useChangeProfile()
    const [isEditing, setIsEditing] = useState(false)
    const [errorsMsg, setErrorsMsg] = useState(null)
    const [profilePhoto, setProfilePhoto] = useState(null)
    const [isBusy, setIsBusy] = useState(false)

    const defaultProfileValues = useMemo(
        () => ({
            first_name: user?.firstName,
            last_name: user?.lastName,
            language_code: JS_CONF.lang ?? user?.lang
        }),
        [user?.firstName, user?.lastName, user?.lang]
    )

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
        getFieldState
    } = useForm({ defaultValues: defaultProfileValues, resolver: yupResolver(profileSetupValidation) })

    const onEditBtnClick = useCallback(() => {
        setIsEditing(true)
    }, [setIsEditing])

    const onProfilePhotoChange = useCallback(
        (photoBase64: string | ArrayBuffer) => {
            setProfilePhoto(photoBase64)
        },
        [setProfilePhoto]
    )

    const onCloseEditing = useCallback(() => {
        setIsEditing(false)
        reset(defaultProfileValues)
    }, [defaultProfileValues, reset])

    const onSubmitEditing = useCallback(
        async values => {
            const submitData = { ...values, photo: profilePhoto }

            setIsBusy(true)

            await submitProfileChanges.mutateAsync(submitData, {
                onSuccess: async () => {
                    setIsEditing(false)

                    const { isDirty: isDirtyLanguageCode } = getFieldState("language_code")

                    if (isDirtyLanguageCode) {
                        window.location.reload()
                    }
                },
                onError: e => setErrorsMsg(getErrorMessages(e)),
                onSettled: () => setIsBusy(false)
            })
        },
        [profilePhoto, getFieldState, submitProfileChanges]
    )

    const languageOptions = useMemo(() => {
        if (user) {
            return getLanguageOptions(user.cohortAvailableLanguages)
        }
        return []
    }, [user])

    const defaultLanguageIndex = useMemo(() => {
        const index = languageOptions?.findIndex(lang => lang.value === JS_CONF.lang)
        return (
            (index < 0 ? languageOptions?.findIndex(lang => lang.value === "en") : index) ||
            languageOptions?.findIndex(l => l.value === user?.lang)
        )
    }, [languageOptions, user?.lang])

    const onLanguageSelect = (language: Option) => {
        setValue("language_code", language.value, { shouldDirty: true })
    }

    return (
        <ParticipantLayout leftSidebar={<LeftSidebar />}>
            <ParticipantWrapper size="md">
                <div className="d-flex justify-content-between mb-5 participant-wrapper-heading">
                    <Heading tag="h1">{t("My Profile")}</Heading>
                    {!isEditing && (
                        <Button variant="outline" className="profile-edit__btn" onClick={onEditBtnClick}>
                            {t("Edit profile")}
                        </Button>
                    )}
                </div>

                <Heading tag="h4" fontSize={24} className="mb-3">
                    {t("Your information")}
                </Heading>
                <div className="row">
                    <div className="col-12">
                        <TextInput
                            name="first_name"
                            register={register}
                            errors={errors}
                            disabled={!isEditing}
                            className={classNames("profile-edit__input prefilled")}
                            placeholder={t("My Firstname")}
                            Icon={!isEditing ? <LockSvgGray /> : null}
                        />
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-12">
                        <TextInput
                            name="last_name"
                            register={register}
                            errors={errors}
                            disabled={!isEditing}
                            className="profile-edit__input prefilled"
                            placeholder={t("My Lastname")}
                            Icon={!isEditing ? <LockSvgGray /> : null}
                        />
                    </div>
                </div>
                {languageOptions.length > 1 && (
                    <div className="row flex-column mb-4">
                        <div className="col-12 col-md-3 pr-0 mb-3 d-flex align-items-center">
                            <Heading tag="h4" fontSize={16} className="m-0">
                                {t("Preferred Language")}:
                            </Heading>
                        </div>
                        <div className="col-sm-12 col-md-5">
                            <Select
                                backgroundWhite
                                optionsList={languageOptions}
                                showDisabledIcon={!isEditing}
                                onSelect={onLanguageSelect}
                                disabled={!isEditing}
                                defaultOptionIndex={defaultLanguageIndex}
                            />
                        </div>
                    </div>
                )}
                <div className="row mb-50">
                    <div className="col-12">
                        <ProfilePhotoHandler
                            onChange={onProfilePhotoChange}
                            defaultPhoto={user?.photo}
                            disabled={!isEditing}
                        />
                    </div>
                </div>
                <WarningMessage message={errorsMsg} />
                {isEditing && (
                    <div className="d-flex justify-content-center">
                        <Button
                            variant="outline-blank"
                            disabled={isBusy}
                            className="mr-2 profile-edit__btn"
                            onClick={onCloseEditing}>
                            {t("Cancel")}
                        </Button>
                        <Button onClick={handleSubmit(onSubmitEditing)} isBusy={isBusy}>
                            {t("Save Changes")}
                        </Button>
                    </div>
                )}
            </ParticipantWrapper>
        </ParticipantLayout>
    )
}

export default Profile
