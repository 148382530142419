import React from "react"

import { type NavigateFunction, useNavigate } from "react-router-dom"

import { GroupSessionParticipant } from "main-app/models/participants"
import type { TEmptyCallback } from "main-app/shared/types/functions"

interface IUsePaginatedSessions {
    sessionId: number
    isRsvpModalShown: boolean
    isAttendanceModalShown: boolean
    selectedRsvp: GroupSessionParticipant | null
    handleClickParticipant(id: number): void
    handleOpenAttendanceModal(id: number): void
    handleCloseAttendanceModal: TEmptyCallback
    handleSubmitAttendanceModal(cb?: Function): void
    handleCloseRsvpModal: TEmptyCallback
    handleOpenRsvpModal(participant: GroupSessionParticipant): void
}

function usePaginatedSessions(): ReturnType<() => IUsePaginatedSessions> {
    const [sessionId, setSessionId]: [number, React.Dispatch<React.SetStateAction<number>>] =
        React.useState<number>(null)

    const [isRsvpModalShown, setIsRsvpModalShown]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] =
        React.useState(false)
    const [selectedRsvp, setSelectedRsvp]: [
        GroupSessionParticipant,
        React.Dispatch<React.SetStateAction<GroupSessionParticipant>>
    ] = React.useState<GroupSessionParticipant | null>(null)

    const [isAttendanceModalShown, setIsAttendanceModalShown]: [
        boolean,
        React.Dispatch<React.SetStateAction<boolean>>
    ] = React.useState(false)

    const navigate: NavigateFunction = useNavigate()

    const handleOpenAttendanceModal: (id: number) => void = React.useCallback(
        (id: number): void => (setSessionId(id), setIsAttendanceModalShown(true)),
        []
    )

    const handleCloseAttendanceModal: TEmptyCallback = React.useCallback(() => setIsAttendanceModalShown(false), [])

    const handleSubmitAttendanceModal: (cb?: Function) => void = React.useCallback((cb?: Function): void => cb?.(), [])

    const handleClickParticipant: (id: number) => void = React.useCallback(
        (id: number): void => navigate(`/participant-works/${id}`),
        [navigate]
    )

    const handleOpenRsvpModal: (participantSession: GroupSessionParticipant) => void = React.useCallback(
        (participant: GroupSessionParticipant) => (setIsRsvpModalShown(true), setSelectedRsvp(participant)),
        []
    )

    const handleCloseRsvpModal: TEmptyCallback = React.useCallback(
        (): void => (setIsRsvpModalShown(false), setSelectedRsvp(null)),
        []
    )

    return {
        sessionId,
        isRsvpModalShown,
        isAttendanceModalShown,
        selectedRsvp,
        handleOpenAttendanceModal,
        handleCloseAttendanceModal,
        handleSubmitAttendanceModal,
        handleOpenRsvpModal,
        handleCloseRsvpModal,
        handleClickParticipant
    }
}

export { usePaginatedSessions, type IUsePaginatedSessions }
