import React, { memo, useCallback, useEffect, useMemo, useRef } from "react"

import classNames from "classnames"
import { Link, matchPath, useLocation } from "react-router-dom"

import useMediaQuery from "common/hooks/use-media-query"
import { type IUseTranslation, useTranslation } from "common/hooks/use-translation"

import useCoachingSessions from "main-app/api/use-coaching-sessions"
import useOnboardingSteps from "main-app/api/use-onboarding-steps"
import useSpotCoaching from "main-app/api/use-spot-coaching"
import {
    CARE_TEAM_EMAIL,
    ConfigKeys,
    MAX_WIDTH_MOBILE_MEDIA,
    ParticipantUrls,
    SessionScheduleStatus
} from "main-app/constants"
import { useAuthContext } from "main-app/context/Auth"
import { useStoreContext } from "main-app/context/GlobalStore"
import { ModuleNavigation } from "main-app/entities/module-navigation"
import { CohortModalities } from "main-app/models/types"
import ProductTypeLogo from "main-app/shared/ProductTypeLogo"
import Avatar from "main-app/shared/avatar/Avatar"
import Sidebar from "main-app/shared/sidebar/Sidebar"
import { BetaStatusTag } from "main-app/shared/tags/BetaStatusTag"
import type { TEmptyCallback } from "main-app/shared/types/functions"
import LiveNowSvg from "main-app/svgs/LiveNowSvg"
import { isIndidualCoachSession } from "main-app/utils/gates/session"

import { OnboardingSteps } from "../onboarding/constants"

interface IProps {
    tag?: "div" | "aside"
    closedMenu?: boolean
    integrated?: boolean
}

const LeftSidebar: React.FC<IProps> = ({ tag = "div", closedMenu = false, integrated = false }: IProps) => {
    const { t }: IUseTranslation = useTranslation()
    const { logout, user } = useAuthContext()
    const { toggleBurgerMenu, isOpenBurgerMenu, getConfig } = useStoreContext()
    const isMobileView = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA)
    const { pathname } = useLocation()
    const { data: sessions } = useCoachingSessions()
    const { data: productType } = useSpotCoaching()
    const { data: steps } = useOnboardingSteps()

    const sidebarTogglerButtonRef = useRef<HTMLButtonElement>(null)

    useEffect(() => {
        integrated && closedMenu && setTimeout(() => sidebarTogglerButtonRef?.current?.click(), 0)
    }, [integrated, closedMenu])

    const notScheduledSessions = useMemo(() => {
        return (
            sessions?.upcoming_sessions.filter(
                session =>
                    !session.session_time &&
                    session.event_status === SessionScheduleStatus.NOT_SCHEDULED &&
                    (session.cohort_modality === CohortModalities.Individual || isIndidualCoachSession(session))
            ) ?? []
        )
    }, [sessions])

    const handleNavigation: TEmptyCallback = useCallback((): void => {
        if (isOpenBurgerMenu && isMobileView) {
            toggleBurgerMenu()
        }
    }, [isMobileView, isOpenBurgerMenu, toggleBurgerMenu])

    const hasDiagnosticeStep = steps?.previousSteps?.some(
        step => step === OnboardingSteps.DIAGNOSTIC_STEP || step === OnboardingSteps.DIAGNOSTIC_RESPONSES_STEP
    )

    const isSurveysPageVisible: boolean = useMemo((): boolean => user?.hasSurveys, [user?.hasSurveys])

    const showDashboard: boolean = getConfig(ConfigKeys.showDashboard) as boolean

    return (
        <Sidebar
            tag={tag}
            sidebarLogo={<ProductTypeLogo />}
            closedMenu={closedMenu}
            sidebarTogglerButtonRef={sidebarTogglerButtonRef}>
            {isMobileView && (
                <Avatar url={user?.photo} circleFillColor="#FFFFFF" width={45} height={45} className="mb-4 mx-auto" />
            )}
            {showDashboard && <ModuleNavigation className="mt-30" />}
            <ul className="menu-capitalized no-margin">
                <li className={classNames({ active: pathname.includes(ParticipantUrls.SESSION_LIST) })}>
                    <Link to={ParticipantUrls.SESSION_LIST} onClick={handleNavigation}>
                        <span className={classNames({ "mr-2": sessions?.live_session || notScheduledSessions.length })}>
                            {productType?.sessionPageName}
                        </span>
                        {notScheduledSessions.length > 0 && !sessions?.live_session && <span className="red-dot" />}
                        {sessions?.live_session && <LiveNowSvg width={22} height={18} />}
                    </Link>
                </li>

                {isSurveysPageVisible && (
                    <li tabIndex={0} className={classNames({ active: matchPath(ParticipantUrls.SURVEYS, pathname) })}>
                        <Link to={ParticipantUrls.SURVEYS} onClick={handleNavigation}>
                            <span>{t("participantSide.dashboard.sidebar.navigationList.surveys.label")}</span>
                        </Link>
                    </li>
                )}

                {hasDiagnosticeStep && (
                    <li className={classNames({ active: pathname.includes(ParticipantUrls.DIAGNOSTIC) })}>
                        <Link to={ParticipantUrls.DIAGNOSTIC} onClick={handleNavigation}>
                            <span>{t("Diagnostic & Goals")}</span>
                        </Link>
                    </li>
                )}

                {isMobileView && (
                    <li className={classNames({ active: pathname.includes(ParticipantUrls.PROFILE) })}>
                        <Link to={ParticipantUrls.PROFILE} onClick={handleNavigation}>
                            <span>{`${t("Profile")} & ${t("Settings")}`}</span>
                        </Link>
                    </li>
                )}

                {user?.availableCoachingPlan && (
                    <li className={classNames({ active: pathname.includes(ParticipantUrls.COACHING_PLAN) })}>
                        <Link to={ParticipantUrls.COACHING_PLAN} onClick={handleNavigation}>
                            <span>{t("Coaching Plan")}</span>
                        </Link>
                    </li>
                )}

                {user?.practiceLab && (
                    <li
                        className={classNames({
                            active:
                                matchPath(ParticipantUrls.COACHEES, pathname) ||
                                matchPath(ParticipantUrls.SIMULAB_CHAT, pathname)
                        })}>
                        <Link to={ParticipantUrls.COACHEES} onClick={handleNavigation}>
                            <span className="d-flex align-items-center justify-content-center justify-content-md-start">
                                {t("Wiser SimuLab")} <BetaStatusTag className="ml-2" size="s" />
                            </span>
                        </Link>
                    </li>
                )}

                <li>
                    <a href={`mailto:${productType?.supportEmail}` || CARE_TEAM_EMAIL}>
                        <span>
                            {t("Contact")} {productType?.supportTeamName}
                        </span>
                    </a>
                </li>
                <li className="d-md-none">
                    <Link to="#" onClick={logout}>
                        <span>{t("Sign out")}</span>
                    </Link>
                </li>
            </ul>
        </Sidebar>
    )
}

export default memo(LeftSidebar)
