import React from "react"

import { Navigate, Route, Routes } from "react-router-dom"

import { ScrollToTop } from "common/components/ScrollToTop"
import { useLanguage } from "common/hooks/use-language"
import useUserActivity from "common/hooks/use-user-activity"
import { isNullOrUndefined } from "common/utils/gates"
import { setLuxonLocale } from "common/utils/lang"

import { makePing } from "main-app/api/mutations/use-ping"
import "main-app/assets/main-app.scss"
import { ERROR_TEXT, INDEX_URL, ParticipantUrls, userRoles } from "main-app/constants"
import { type IAuthContext, useAuthContext } from "main-app/context/Auth"
import PostReflectionModalWrapper from "main-app/elements/reflection-modals/PostReflectionModalWrapper"
import { PrivacyPolicyPage } from "main-app/pages/privacy-policy"
import { authRoutes, coachRoutes, participantRoutes, unanothorizedRoutes } from "main-app/routes"
import ProtectedRoute from "main-app/routes/protected-route"
import { ErrorPage } from "main-app/shared/error-page/ErrorPage"
import { ForbiddenComponent } from "main-app/shared/error-page/ForbiddenComponent"
import { NotFoundPage } from "main-app/shared/error-page/NotFoundPage"
import TestErrorsPage from "main-app/shared/error-page/TestErrorsPage"
import ReloadAppModal from "main-app/shared/reload-app-modal/ReloadAppModal"

import Footer from "./Footer"
import Header from "./Header"
import WaitingListPage from "./waiting-list/WaitingListPage"

setLuxonLocale()

const Main: React.FC = (): React.ReactElement => {
    const { user, isLoading, logout }: IAuthContext = useAuthContext()

    useUserActivity({
        onInactivity: (): void => (
            console.info("%c Info: user is inactive", "background: #222; color: #bada55"), logout(), void 0
        ),
        onActivity: async (): Promise<void> => (await makePing(), void 0),
        enabled: !isNullOrUndefined(user),
        ...(!isNullOrUndefined(user?.browserSessionTtl) && {
            inactivityTime: Number(user?.browserSessionTtl) * 1000
        })
    })

    useLanguage(user)

    return (
        <>
            <ScrollToTop />
            <Routes>
                <Route path={INDEX_URL} element={<ProtectedRoute user={user} isAllowed isLoading={isLoading} />} />

                {authRoutes.map(({ path, Component }) => (
                    <Route
                        key={path}
                        path={path}
                        element={
                            <>
                                <Header />
                                <Component />
                                <Footer />
                            </>
                        }
                    />
                ))}

                {participantRoutes.map(({ path, Component }) => (
                    <Route
                        key={path}
                        path={path}
                        element={
                            <ProtectedRoute
                                redirectPath="/forbidden"
                                isAllowed={!!user && userRoles.participant.includes(user?.role)}
                                user={user}
                                isLoading={isLoading}>
                                <Component />
                            </ProtectedRoute>
                        }
                    />
                ))}

                {coachRoutes.map(({ path, Component, ...rest }) => (
                    <Route
                        key={path}
                        path={path}
                        element={
                            <ProtectedRoute
                                redirectPath="/forbidden"
                                isAllowed={!!user && userRoles.coach.includes(user?.role)}
                                user={user}
                                isLoading={isLoading}>
                                <Component {...rest} />
                            </ProtectedRoute>
                        }
                    />
                ))}

                {unanothorizedRoutes.map(({ path, Component }) => (
                    <Route path={path} element={<Component />} key={path} />
                ))}

                <Route path={ParticipantUrls.WAITING_LIST} element={<WaitingListPage />} />
                <Route path={ParticipantUrls.WAITING_LIST_SSO} element={<WaitingListPage />} />
                <Route path="/privacy" element={<PrivacyPolicyPage />} />

                <Route path="/error" element={<ErrorPage errorContent={ERROR_TEXT} />} />
                <Route path="/internal-error" element={<ErrorPage errorContent={ERROR_TEXT} />} />
                <Route path="/test-error-page" element={<TestErrorsPage />} />

                <Route
                    path={ParticipantUrls.END_OF_JOURNEY_PAGE}
                    element={<Navigate to={ParticipantUrls.OFFBOARDING} replace />}
                />

                <Route path="/forbidden" element={<ForbiddenComponent />} />
                <Route path="404" element={<NotFoundPage />} />

                <Route path="*" element={<NotFoundPage />} />
            </Routes>

            <PostReflectionModalWrapper />
            <ReloadAppModal />
        </>
    )
}

export default Main
