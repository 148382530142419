import * as Sentry from "@sentry/react"
import { MutationCache, Query, QueryCache, QueryClient } from "@tanstack/react-query"
import { AxiosError } from "axios"

import { HTTPStatus, ONE_MINUTE_STALE_TIME } from "common/constants"

import { ServerStateKeys } from "./constants"
import { handle401Error } from "./utils/handle-401-error"
import { triggerErrorBoundary } from "./utils/throw-error"

const captureErrors = (error: AxiosError, scope: Sentry.Scope): void => {
    scope.setExtras({
        method: error?.config?.method,
        request_payload: error?.config?.data || null,
        status_code: error?.response?.status,
        url: error?.config?.url
    })
    scope.setTag("build_config", process.env.NODE_ENV)
    scope.setTag("request_url", error?.config?.url)
    scope.setTag("method", error?.config?.method)
}

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            staleTime: ONE_MINUTE_STALE_TIME,
            retry: false,
            useErrorBoundary: (error: AxiosError) => {
                return error?.response?.status >= HTTPStatus.INTERNAL_SERVER_ERROR
            }
        }
    },
    mutationCache: new MutationCache({
        onError: (err: AxiosError, _variables, _context, mutation): void => {
            Sentry.withScope(scope => {
                scope.setContext("mutation", {
                    mutationId: mutation.mutationId,
                    variables: mutation.state.variables,
                    data: err?.response?.data || null
                })
                if (mutation.options.mutationKey) {
                    scope.setFingerprint(Array.from(mutation.options.mutationKey) as string[])
                }
                captureErrors(err, scope)
                Sentry.captureException(err)
            })
            if (err?.response?.status === HTTPStatus.UNAUTHORIZED) {
                console.log("Unauthorized error", { err })
                handle401Error()
            }
            if (err?.response?.status >= HTTPStatus.INTERNAL_SERVER_ERROR) {
                triggerErrorBoundary(err)
            }
        }
    }),
    queryCache: new QueryCache({
        onError: (err: AxiosError, query: Query): void => {
            Sentry.withScope(scope => {
                scope.setContext("query", { queryHash: query.queryHash, data: err?.response?.data || null })
                scope.setFingerprint([query.queryHash.replaceAll(/[0-9]/g, "0")])
                captureErrors(err, scope)
                Sentry.captureException(err)
            })

            // @ts-expect-error cache is private property
            const user = query.cache.queries.find((query: Query): boolean =>
                query.queryKey.includes(ServerStateKeys.User)
            )?.state?.data

            if (err?.response?.status === HTTPStatus.UNAUTHORIZED) {
                console.log("Unauthorized error", { err, query, user })
                handle401Error()
            }
        }
    })
})

export { queryClient }
