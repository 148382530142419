import React from "react"

import { useTranslation } from "react-i18next"
import { Navigate, useParams } from "react-router-dom"

import Heading from "common/components/Heading/Heading"
import { HTTPStatus } from "common/constants"
import { getErrorMessages } from "common/utils/get-error-messages"

import useGroupInfo from "main-app/api/use-group-info"
import { SessionType } from "main-app/constants"
import AttendanceModal from "main-app/elements/attendance-modal/AttendanceModal"
import RsvpModal from "main-app/elements/rsvp-modal/RsvpModal"
import CoachLayout from "main-app/layouts/CoachLayout"
import CareTeamLink from "main-app/shared/CareTeamLink"
import LiveNowLine from "main-app/shared/livenow-line/LiveNowLine"
import useLiveSessionCheck from "main-app/utils/hooks/use-live-session-check"

import { WarningMessage } from "../onboarding/components/WarningMessage"

import GroupParticipants from "./GroupParticipants"
import PaginatedSessions from "./PaginatedSessions"
import SessionAccordion from "./SessionAccordion"
import { type IUsePaginatedSessions, usePaginatedSessions } from "./hooks/use-paginated-sessions-props"

import "./styles.scss"

interface IProps {}

const GroupInfo: React.FC<IProps> = () => {
    const { t } = useTranslation()
    const params = useParams()
    const { data, refetch, isError, error } = useGroupInfo(params.id)

    const session = data?.upcoming_sessions.find(session => session.session_time)

    const {
        selectedRsvp,
        sessionId,
        isRsvpModalShown,
        isAttendanceModalShown,
        handleOpenAttendanceModal,
        handleCloseAttendanceModal,
        handleCloseRsvpModal,
        handleOpenRsvpModal,
        handleClickParticipant
    }: IUsePaginatedSessions = usePaginatedSessions()

    useLiveSessionCheck({ date: session?.session_time }, () => {
        refetch()
    })

    if (isError && error?.response?.status === HTTPStatus.FORBIDDEN) {
        return <Navigate to="/forbidden" replace />
    }

    return (
        <CoachLayout>
            <div className="group-info">
                {data ? (
                    <>
                        <Heading textAlign="center" className="mb-50">
                            {data.group_info.title}
                        </Heading>
                        {data.live_session ? (
                            <div className="mb-50">
                                <div className="mb-3">
                                    <LiveNowLine joinLink={data.live_session.join_url} />
                                </div>

                                <SessionAccordion
                                    isLive
                                    session={data.live_session}
                                    onOpenAttendanceModal={handleOpenAttendanceModal}
                                    onClickRsvpNote={handleOpenRsvpModal}
                                    onClickParticipant={handleClickParticipant}
                                />
                            </div>
                        ) : null}
                        <Heading tag="h2" fontSize={24} textAlign="center" className="mb-3">
                            {t("Upcoming Sessions")}
                        </Heading>
                        <PaginatedSessions
                            sessions={data.upcoming_sessions}
                            type={SessionType.UPCOMING}
                            onClickParticipant={handleClickParticipant}
                            onClickRsvpNote={handleOpenRsvpModal}
                        />

                        <Heading tag="h2" fontSize={24} textAlign="center" className="mt-50 mb-3">
                            {t("Past Sessions")}
                        </Heading>
                        <PaginatedSessions
                            sessions={data.passed_sessions}
                            type={SessionType.PAST}
                            onOpenAttendanceModal={handleOpenAttendanceModal}
                            onClickRsvpNote={handleOpenRsvpModal}
                            onClickParticipant={handleClickParticipant}
                        />

                        <div className="line-separator" />
                        <Heading tag="h2" fontSize={24} textAlign="center" className="mt-3 mb-3">
                            {t("Group Participants")}
                        </Heading>
                        <div className="mb-50">
                            <GroupParticipants participants={data.group_info.participants_info} />
                        </div>
                        <CareTeamLink />
                    </>
                ) : null}
                {isError ? (
                    <WarningMessage
                        message={error ? getErrorMessages(error)?.join("") : t("An error occurred, please try again.")}
                    />
                ) : null}
            </div>

            {isAttendanceModalShown && (
                <AttendanceModal
                    show={isAttendanceModalShown}
                    sessionId={sessionId}
                    onClose={handleCloseAttendanceModal}
                />
            )}
            {isRsvpModalShown && (
                <RsvpModal show={isAttendanceModalShown} onClose={handleCloseRsvpModal} participant={selectedRsvp} />
            )}
        </CoachLayout>
    )
}

export default GroupInfo
