import { useCallback } from "react"

import { type QueryFunction, type QueryObserverOptions, type UseQueryResult, useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios"

import { http } from "common/http"

import Urls from "main-app/api/urls"
import { ServerStateKeys } from "main-app/constants"

interface IModel {
    id: number
    startTime: string
    endTime: string
}

interface IDto {
    id: number
    start: string
    end: string
}

type TParams = QueryObserverOptions & {
    id?: number
}

type TQuery = UseQueryResult<IModel[], AxiosError>

function adapter(dtos: IDto[]): IModel[] {
    return dtos.map(
        ({ end, start, ...rest }: IDto): IModel => ({
            ...rest,
            startTime: start,
            endTime: end
        })
    )
}

const fetcher: QueryFunction<IDto[], [string, { id: number }]> = async ({ queryKey }): Promise<IDto[]> => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_key, { id: originalSessionId }] = queryKey
    const { data } = await http.get(Urls.getAvailableGuestSessions({ originalSessionId }))

    return data
}

function useHook({ id, ...params }: TParams): TQuery {
    return useQuery({
        queryKey: [ServerStateKeys.AvailableGuestSessions, { id }],
        queryFn: useCallback(fetcher, []),
        select: useCallback(adapter, []),
        enabled: params.enabled
    })
}

export {
    useHook as useAvailableGuestSessionsQuery,
    type IModel as IAvailableGuestSessionModel,
    type TQuery as TAvailableGuestSessionsQuery,
    fetcher as fetchAvailableGuestSessions
}
