import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"

import classNames from "classnames"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import { isNullOrUndefined } from "common/utils/gates"

import { EXTRA_PARTY_TYPE, SessionScheduleStatus } from "main-app/constants"
import { useAuthContext } from "main-app/context/Auth"
import { ThreeWayAvatar, ThreeWayInfo, ThreeWayManager, ThreeWayManagerAvatar } from "main-app/entities/three-way"
import { GuestParticipantSwapSessionOpener } from "main-app/features/guest-participant/ui/GuestParticipantSwapSessionOpener"
import { GroupSessionParticipant } from "main-app/models/participants"
import { AttendanceStatuses, CohortModalities } from "main-app/models/types"
import Avatar from "main-app/shared/avatar/Avatar"
import Button from "main-app/shared/button/Button"
import ChevronToggle from "main-app/shared/chevron-toggle/ChevronToggle"
import { GuestParticipantAvatar } from "main-app/shared/guest-participant-avatar/GuestParticipantAvatar"
import { CheckMarkAttendee } from "main-app/svgs/CheckMarkAttendee"
import { CrossMarkAttendee } from "main-app/svgs/CrossMarkAttendee"
import { FeedbackSubmitSvg } from "main-app/svgs/FeedbackSubmitSvg"
import GroupIcon from "main-app/svgs/GroupIcon"
import { NoFeedbackSvg } from "main-app/svgs/NoFeedbackSvg"
import { formatDateSessions, getTimezoneAbbreviation } from "main-app/utils/date"
import useRoles from "main-app/utils/hooks/use-roles"
import { possessiveForm } from "main-app/utils/string"

interface IProps {
    isLive?: boolean
    isUpcoming?: boolean
    isPast?: boolean
    isAvailable?: boolean
    session?: any // TODO add types
    onClickParticipant?: (id: number) => void
    onNoFeedbackClick?: (sessionId: number, moduleId: number) => void
    onOpenAttendanceModal?: (sessionId: number) => void
    onClickReschedule?: (session) => void
    onClickRsvpNote?: (participant: GroupSessionParticipant) => void
    isReflectionModalOpenableBasedOnProductTypeConfig?: boolean
}

const SessionAccordion: React.FC<IProps> = ({
    isLive = false,
    isUpcoming = false,
    isPast = false,
    isAvailable = true,
    isReflectionModalOpenableBasedOnProductTypeConfig = true,
    onNoFeedbackClick,
    onClickParticipant,
    onClickReschedule,
    onOpenAttendanceModal,
    onClickRsvpNote,
    session
}) => {
    const { t } = useTranslation()
    const params = useParams()
    const { isCoaches, isParticipant } = useRoles()
    const [isActive, setIsActive] = useState(false)
    const contentRef = useRef<HTMLDivElement>()

    const { user } = useAuthContext()

    useEffect(() => {
        setIsActive(isLive || +params.sessionId === session.session_id)
    }, [isLive, params.sessionId])

    const onSessionClick = () => {
        const hasNoFeedback =
            isPast && !session?.module_reflection_passed && isParticipant && session?.marked_attendance

        if (hasNoFeedback) {
            onNoFeedbackClick?.(session?.session_id, session?.module?.id)
            return
        }

        setIsActive(prev => !prev)
    }

    const isIndividualSession = session?.cohort_modality === CohortModalities.Individual

    const participants = session.participants_info
        ? Array.isArray(session?.participants_info)
            ? session?.participants_info
            : [...session?.participants_info?.participants, ...session?.extra_parties_info]
        : []

    const isEditable = useMemo(
        () => participants.some(participant => participant.attendance_status !== null),
        [participants]
    )

    const canReschedule = session.allow_reschedule

    const renderBadgeIcon = useCallback(
        participant => {
            if (!isCoaches || !participant.attendance_status) {
                return null
            }

            return participant.attendance_status === AttendanceStatuses.PRESENT ? (
                <CheckMarkAttendee type="marked" width={20} height={20} enableBorder />
            ) : (
                <CrossMarkAttendee type="marked" width={20} height={20} enableBorder />
            )
        },
        [participants]
    )

    const showReschedule = canReschedule && isParticipant && isUpcoming
    const isThreeWay = session?.is_three_way_session
    const threeWayManager = session?.extra_parties_info?.find(party => !!party)
    const isThreeWayParticipantManager = type => type === EXTRA_PARTY_TYPE.Manager

    const avatar = useMemo(() => {
        if (isIndividualSession || session?.coach) {
            const photoUrl = isIndividualSession && !isParticipant ? participants?.[0]?.photo : session.coach.photo

            return isThreeWay ? (
                <ThreeWayAvatar url={photoUrl} alt="coach photo" width={35} height={35} />
            ) : (
                <Avatar url={photoUrl} width={40} height={40} alt="coach photo" />
            )
        }

        return <GroupIcon width={40} heigth={40} />
    }, [session])

    const isExpiredSchedulingWindow = isPast && isNullOrUndefined(session?.session_time)

    const scheduleTime = useMemo(() => {
        if (isExpiredSchedulingWindow) {
            return t("Expired")
        }

        return session?.event_status === SessionScheduleStatus.NOT_SCHEDULED
            ? t("Not yet scheduled")
            : formatDateSessions(session?.session_time)
    }, [session])

    return (
        <div className="session-accordion-container">
            <div className={classNames("session-accordion", { active: isActive })} onClick={onSessionClick}>
                <div className="session-accordion__group-image">{avatar}</div>
                <div className="session-accordion__group-info">
                    <span className="font-bold d-block">{session?.session_type_pref_lang?.name}</span>
                    <span className="color-gray fs-14">
                        {session?.session_type?.duration} {t("min")}
                        {isThreeWay && ", "}
                    </span>
                    <span className="color-gray fs-14 d-block d-md-inline">
                        {isThreeWay ? `${t("three-way")}` : ""}
                    </span>
                </div>
                <div className="session-accordion__group-time">
                    <span className={classNames("d-block", { "font-extrabold": isExpiredSchedulingWindow })}>
                        {scheduleTime}
                    </span>
                    <span className={classNames("color-gray fs-14", { invisible: isExpiredSchedulingWindow })}>
                        {getTimezoneAbbreviation()}
                    </span>
                </div>
                <div className="d-flex align-items-center">
                    {isPast && isCoaches && !isEditable && (
                        <div className="session-accordion__group-action">
                            <button
                                className="btn btn--brand"
                                onClick={() => onOpenAttendanceModal(session?.session_id)}>
                                {t("Mark Attendance")}
                            </button>
                        </div>
                    )}
                    {isPast &&
                    !isActive &&
                    isParticipant &&
                    session.marked_attendance &&
                    isReflectionModalOpenableBasedOnProductTypeConfig ? (
                        session?.module_reflection_passed ? (
                            <FeedbackSubmitSvg />
                        ) : (
                            <NoFeedbackSvg />
                        )
                    ) : null}
                    {isPast && !isActive && isParticipant ? null : <ChevronToggle active={isActive} />}
                </div>
            </div>
            <div
                className="session-accordion__body"
                ref={contentRef}
                style={isActive ? { height: `${contentRef?.current?.scrollHeight}px` } : { height: "0px" }}>
                {isAvailable ? (
                    <>
                        {isCoaches && isUpcoming && (
                            <div className="session-accordion__body-info">
                                <span>{t("Click a participant to view their work on the platform:")}</span>
                            </div>
                        )}
                        {isThreeWay && !isCoaches && threeWayManager ? (
                            <div className="session-accordion__body-three-way">
                                <ThreeWayInfo threeWayManager={new ThreeWayManager(threeWayManager)} />
                            </div>
                        ) : null}
                        {!isCoaches && (
                            <div className="text-center session-accordion__body-rsvp">
                                <p className="mb-2">
                                    {t("My RSVP:")}{" "}
                                    <span className="color-gray font-italic text-capitalize">
                                        {session?.invitation?.status}
                                    </span>
                                </p>
                                {session?.invitation?.comment && (
                                    <p className="color-gray font-italic mb-2">
                                        &quot;{session?.invitation?.comment}&quot;
                                    </p>
                                )}
                            </div>
                        )}

                        {(isCoaches || !isThreeWay) && (
                            <div
                                className={classNames("session-accordion__body-participants", {
                                    center: participants?.length <= 3
                                })}>
                                {participants?.map(participant => (
                                    <div
                                        className="session-accordion__body-participant text-center cursor-pointer"
                                        key={participant.id}
                                        onClick={() =>
                                            onClickParticipant &&
                                            isCoaches &&
                                            !isThreeWayParticipantManager(participant.extra_party_type) &&
                                            onClickParticipant(participant.id)
                                        }>
                                        {isThreeWayParticipantManager(participant.extra_party_type) ? (
                                            <ThreeWayManagerAvatar badgeIcon={renderBadgeIcon?.(participant)} />
                                        ) : (
                                            <div className="participant-avatar">
                                                {participant.is_guest ? (
                                                    <GuestParticipantAvatar
                                                        url={participant.photo}
                                                        alt={participant.first_name}
                                                        badgeIcon={renderBadgeIcon(participant)}
                                                    />
                                                ) : (
                                                    <Avatar
                                                        url={participant.photo}
                                                        alt={participant.first_name}
                                                        badgeIcon={renderBadgeIcon(participant)}
                                                    />
                                                )}
                                            </div>
                                        )}

                                        <p className="mb-0 fs-14">{participant.first_name}</p>
                                        <p className="color-gray fs-14">
                                            {isThreeWayParticipantManager(participant.extra_party_type)
                                                ? participant?.invitation_status
                                                : participant?.invitation?.status}
                                            {participant?.invitation?.comment ? (
                                                <button
                                                    type="button"
                                                    className="cursor-pointer default-btn color-brand"
                                                    onClick={() => onClickRsvpNote(participant)}>
                                                    ({t("see note")})
                                                </button>
                                            ) : null}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        )}
                        <div
                            className={classNames("session-accordion__body-actions text-center d-none", {
                                "d-block": isLive || isUpcoming || (isEditable && isCoaches && isPast)
                            })}>
                            {(isLive || isUpcoming) && (
                                <>
                                    {showReschedule && (
                                        <Button
                                            onClick={() => onClickReschedule(session)}
                                            className="mr-3"
                                            variant="outline">
                                            {t("Edit Session")}
                                        </Button>
                                    )}
                                    <GuestParticipantSwapSessionOpener
                                        sessionId={session.session_id}
                                        sessionTime={session.session_time}
                                        shouldBeHidden={!isParticipant || showReschedule || !user.guestSessionsEnabled}
                                    />
                                    <a
                                        href={session.join_url}
                                        className={classNames({ "disable-link": !isLive })}
                                        target="_blank"
                                        rel="noreferrer">
                                        <Button variant={isUpcoming ? "disabled" : "brand"} disabled={isUpcoming}>
                                            {t("Join Session")}
                                        </Button>
                                    </a>

                                    {isCoaches && (
                                        <Button
                                            className={classNames("ml-md-3 m-sm-0")}
                                            variant={isUpcoming ? "disabled" : "outline-secondary"}
                                            onClick={() => onOpenAttendanceModal(session?.session_id)}
                                            disabled={isUpcoming}>
                                            {t("Mark Attendance")}
                                        </Button>
                                    )}

                                    {isUpcoming && (
                                        <p className="color-gray m-0 mt-3 fs-14 font-italic">
                                            {t(
                                                "You will be able to join the session 15 minutes before its start time."
                                            )}{" "}
                                            {isCoaches && (
                                                <>{t("Once it begins, you will be able to mark attendance.")}</>
                                            )}
                                        </p>
                                    )}
                                </>
                            )}
                            {isEditable && isCoaches && isPast && (
                                <div className="text-center">
                                    <button
                                        className="btn color-brand"
                                        onClick={() => onOpenAttendanceModal(session?.session_id)}>
                                        {participants?.length === 1 && isIndividualSession
                                            ? t("edit {{participantName}} attendance", {
                                                  participantName: possessiveForm(participants[0].first_name)
                                              })
                                            : t("edit attendance")}
                                    </button>
                                </div>
                            )}
                        </div>
                    </>
                ) : isCoaches ? (
                    <div className="p-30">
                        <p className="text-center m-0">
                            {t(
                                "Please take a moment to note which group members attended this group session. Once you’ve marked attendance, you will have the opportunity to assign new modules if needed."
                            )}
                        </p>
                    </div>
                ) : null}
            </div>
        </div>
    )
}

export default SessionAccordion
