import React from "react"

import { type NavigateFunction, useNavigate } from "react-router-dom"

import Heading from "common/components/Heading/Heading"
import { type IUseTranslation, useTranslation } from "common/hooks/use-translation"
import { getIndexPage } from "common/utils/get-index-page"

import LeftSidebar from "main-app/components/dashboard/LeftSidebar"
import { type IAuthContext, useAuthContext } from "main-app/context/Auth"
import ParticipantLayout from "main-app/layouts/ParticipantLayout"
import PageLoader from "main-app/shared/spinner/PageLoader"
import type { TEmptyCallback } from "main-app/shared/types/functions"

// eslint-disable-next-line import/no-useless-path-segments
import { SurveysContainer } from "./"

import "./Surveys.styles.scss"

const Surveys: React.FC = (): React.ReactElement => {
    const { t }: IUseTranslation = useTranslation()

    const { user }: IAuthContext = useAuthContext()

    const navigate: NavigateFunction = useNavigate()

    React.useLayoutEffect(
        (): TEmptyCallback => (
            !user?.hasSurveys && React.startTransition((): void => navigate(getIndexPage(user, true))), void 0
        ),
        [user, navigate]
    )

    return user?.hasSurveys ? (
        <ParticipantLayout
            tag="main"
            leftSidebar={<LeftSidebar tag="aside" />}
            title={
                <div className="surveys__heading">
                    <Heading textAlign="center" className="m-0 fs-36">
                        {t("participantSide.surveysPage.heading")}
                    </Heading>
                </div>
            }>
            <SurveysContainer />
        </ParticipantLayout>
    ) : (
        <PageLoader />
    )
}

export { Surveys }
