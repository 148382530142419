import { useCallback } from "react"

import { type UseMutationResult, useMutation } from "@tanstack/react-query"
import type { AxiosError } from "axios"

import { http } from "common/http"

import { ApiMutationKey } from "main-app/constants"
import type { IJwtTokenPairDto } from "main-app/models/jwt"

import Urls from "../urls"

interface IUserCreatePasswordDto {
    jwt_pair: IJwtTokenPairDto
    id: string
    last_name: string
    email: string
    user_role: string
    cohort_exists: string
}

type TUserCreatePasswordMutationParams = {
    registration_key: string
    password: string
    password_confirm: string
    tz: string
}

type TUserCreatePasswordMutation = UseMutationResult<
    IUserCreatePasswordDto,
    AxiosError,
    TUserCreatePasswordMutationParams
>
type TUseUserCreatePasswordMutationReturn = ReturnType<
    () => TUserCreatePasswordMutation<IUserCreatePasswordDto, AxiosError, TUserCreatePasswordMutationParams>
>

async function loginMutationHandler(params: TUserCreatePasswordMutationParams): Promise<IUserCreatePasswordDto> {
    return http.post(Urls.postUserPassword(params.registration_key), params)
}

function useUserCreatePasswordMutation(): TUseUserCreatePasswordMutationReturn {
    return useMutation<IUserCreatePasswordDto, AxiosError, TUserCreatePasswordMutationParams>(
        [ApiMutationKey.UserPasswordCreate],
        useCallback(loginMutationHandler, [])
    )
}

export { useUserCreatePasswordMutation, type TUserCreatePasswordMutation }
