import React, { useMemo, useState } from "react"

import { type NavigateFunction, useNavigate } from "react-router-dom"

import { type IUseTranslation, useTranslation } from "common/hooks/use-translation"
import { Option } from "common/models/option"
import { isNullOrUndefined } from "common/utils/gates"
import { getUserLanguage } from "common/utils/lang"

import { INDEX_URL } from "main-app/constants"
import { type IAuthContext, useAuthContext } from "main-app/context/Auth"
import { useModulesByParticipant, useSubmitEnrollment } from "main-app/entities/module"
import CareTeamLink from "main-app/shared/CareTeamLink"
import Button from "main-app/shared/button/Button"
import Select from "main-app/shared/select-default/Select"

import "./styles.scss"

export const SelfEnrollmentBlock = () => {
    const { t }: IUseTranslation = useTranslation()
    const navigate: NavigateFunction = useNavigate()
    const { user, refetchUser }: IAuthContext = useAuthContext()
    const { data } = useModulesByParticipant({
        participantId: user?.id,
        sortLanguage: getUserLanguage()
    })
    const submitEnrollment = useSubmitEnrollment(user?.id)
    const [selectedModule, setSelectedModule] = useState(null)
    const [isBusy, setIsBusy] = useState(false)

    const optionsList = useMemo(() => {
        const options =
            data?.map(module => {
                const isAssignedOrCompleted = module.isAssigned || module.isCompleted

                return new Option({
                    value: module.id,
                    label: isAssignedOrCompleted
                        ? `${module.titlePrefLang} (Already Assigned)`
                        : `${module.titlePrefLang}`,
                    disabled: isAssignedOrCompleted
                })
            }) ?? []

        return [new Option({ value: "", label: "---", disabled: true }), ...options]
    }, [data])

    if (!user?.eligibleSelectModule) {
        navigate(INDEX_URL)
        return null
    }

    const noEnrollments = user?.enrolledModules?.length === 0
    const onSelect = (module: Option) => {
        setSelectedModule(module.value)
    }

    const notSelectedModule = isNullOrUndefined(selectedModule)

    const onAssignClick = () => {
        setIsBusy(true)
        submitEnrollment.mutate(selectedModule, {
            onSuccess: async () => {
                await refetchUser()
                navigate(INDEX_URL)
            },
            onSettled: () => {
                setIsBusy(false)
            }
        })
    }

    return (
        <section className="self-enrollment-block">
            <p className="mb-1 font-extrabold text-center">
                <span className="color-brand d-block d-md-inline text-center">{t("Important!")}</span>{" "}
                {noEnrollments
                    ? t("You have no current module assigned. You must self-assign one now.")
                    : t("Your next module is currently unassigned. You must self-assign one now.")}
            </p>
            <p className="mb-40 text-center">
                {noEnrollments
                    ? t("Once you finish assigning the module, you will be able to start Phase 1")
                    : t("Once you finish assigning the module, you will return to your usual Wiser homepage.")}
            </p>

            <div className="d-flex justify-content-center align-items-center w-100 mb-50 flex-column flex-md-row">
                <p className="mb-md-0 mr-md-3">{t("Select a Module:")}</p>
                {optionsList?.length > 0 && (
                    <Select
                        onSelect={onSelect}
                        optionsList={optionsList}
                        variant="secondary"
                        backgroundWhite
                        className="self-enrollment-select"
                    />
                )}
            </div>

            <Button
                disabled={notSelectedModule}
                variant={notSelectedModule ? "disabled-dark-grey" : "brand"}
                isBusy={isBusy}
                onClick={onAssignClick}>
                {t("Assign Module")}
            </Button>

            <CareTeamLink variant="brand" className="fs-14" />
        </section>
    )
}
