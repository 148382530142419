import { useCallback } from "react"

import { QueryClient, type UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query"
import type { AxiosError } from "axios"

import { http } from "common/http"

import { ApiMutationKey } from "main-app/constants"
import type { IJwtTokenLogoutDto } from "main-app/models/jwt"

import Urls from "../urls"

type TLogoutMutation = UseMutationResult<-1, AxiosError, IJwtTokenLogoutDto>

type TUseLogoutMutationReturn = ReturnType<() => TLogoutMutation>

async function handleLogout(params: IJwtTokenLogoutDto): Promise<-1> {
    return http.post(Urls.postLogout(), params)
}

function useLogoutMutation(): TUseLogoutMutationReturn {
    const queryClient: QueryClient = useQueryClient()

    return useMutation<-1, AxiosError, IJwtTokenLogoutDto>(
        [ApiMutationKey.LogoutCreate],
        useCallback(handleLogout, []),
        {
            retry: false,
            onMutate: async (): Promise<void> => queryClient.cancelQueries([ApiMutationKey.LogoutCreate])
        }
    )
}

export { useLogoutMutation, type TLogoutMutation, handleLogout }
