import React from "react"

import { QueryClient, useQueryClient } from "@tanstack/react-query"
import { type NavigateFunction, useNavigate } from "react-router-dom"

import { fetchRespondentSurvey } from "main-app/entities/survey/api/use-respondent-survey"
import { ServerStateKeys } from "main-app/entities/survey/constants"
import { emptyAsyncCallback } from "main-app/shared/types/functions"

import { type TSurveysQuery, useSurveysQuery } from "../api"

import {
    SurveysAttentionBox as AttentionBox,
    SurveysFallbackSpinner as Spinner,
    type TSurveysDataListProps as TListProps // eslint-disable-next-line import/no-useless-path-segments
} from "./"
import type { THandleListItemClickFunction } from "./types"
import { surveysPageUiUtils as utils } from "./utils"

const List: React.LazyExoticComponent<React.FC<TListProps>> = React.lazy(
    (): Promise<{ default: React.FC<TListProps> }> => import("./SurveysDataList")
)

const Container: React.FC = (): React.ReactElement => {
    const { data: surveysData, isFetching: isSurveysDataFetching }: TSurveysQuery = useSurveysQuery()

    const navigate: NavigateFunction = useNavigate()

    const queryClient: QueryClient = useQueryClient()

    const handleListItemClick: THandleListItemClickFunction = React.useCallback(
        async (accessCode: string): Promise<void> =>
            accessCode
                ? (await queryClient.prefetchQuery([ServerStateKeys.RespondentSurvey, accessCode], () =>
                      fetchRespondentSurvey(accessCode)
                  ),
                  React.startTransition(() =>
                      navigate(`/survey?access_code=${accessCode}`, {
                          state: { shouldBackButtonRedirectToSurveysPage: true }
                      })
                  ))
                : emptyAsyncCallback(),
        [queryClient, navigate]
    )

    const isAttentionBoxVisible: boolean = React.useMemo(
        (): boolean => utils.getIsAttentionBoxVisible(surveysData?.results ?? []),
        [surveysData]
    )

    return (
        <section className="surveys__container">
            <div className="surveys__wrapper">
                {isAttentionBoxVisible && (
                    <>
                        <AttentionBox />
                        <hr className="separator" />
                    </>
                )}

                <React.Suspense fallback={<Spinner />}>
                    {isSurveysDataFetching || !surveysData?.results?.length ? (
                        <Spinner />
                    ) : (
                        <List surveysData={surveysData.results} handleListItemClick={handleListItemClick} />
                    )}
                </React.Suspense>
            </div>
        </section>
    )
}

const SurveysContainer: React.NamedExoticComponent = React.memo(Container)

export { SurveysContainer }
