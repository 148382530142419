import React, { Dispatch, type FC, type ReactElement, SetStateAction, useCallback, useState } from "react"

import { CoachRelinkAccountsSuccessfullyLinkedStatusElement as SuccessfullyLinkedStatusElement } from "."
import { Link } from "react-router-dom"

import { type IUseTranslation, useTranslation } from "common/hooks/use-translation"
import { isNullOrUndefined } from "common/utils/gates"

import Button from "main-app/shared/button/Button"
import type { TEmptyCallback } from "main-app/shared/types/functions"

import type { ICoachAccountCalendarToRelinkModel, ICoachAccountZoomToRelinkModel } from "../api"
import { type THandleButtonClickFunctions } from "../types"

type TCoachRelinkAccountsAccountItemProps = (ICoachAccountCalendarToRelinkModel | ICoachAccountZoomToRelinkModel) &
    ({
        isPrimaryAccount?: boolean
        disconnectUrl?: string
    } & THandleButtonClickFunctions)

const CoachRelinkAccountsAccountItem: FC<TCoachRelinkAccountsAccountItemProps> = ({
    profileId,
    profileName,
    relinkUrl,
    isPrimaryAccount,
    disconnectUrl,
    handleDisconnectButtonClick
}: TCoachRelinkAccountsAccountItemProps): ReactElement => {
    const { t }: IUseTranslation = useTranslation()

    const shouldRelinkButtonBeVisible: boolean = !!relinkUrl

    const shouldDeactivateButtonBeVisible: boolean = !isPrimaryAccount && !isNullOrUndefined(disconnectUrl)

    const shouldSuccessfulStatusBeVisible: boolean = !shouldRelinkButtonBeVisible && !shouldDeactivateButtonBeVisible

    const [isDisconnectButtonDisabled, setIsDisconnectButtonDisabled]: [boolean, Dispatch<SetStateAction<boolean>>] =
        useState<boolean>()

    const onDisconnectButtonClick: TEmptyCallback = useCallback(
        () => (disconnectUrl && handleDisconnectButtonClick(profileId), setIsDisconnectButtonDisabled(true)),
        [disconnectUrl, handleDisconnectButtonClick, profileId]
    )

    return (
        <div className="coach-relink-accounts__accounts-list__item">
            <p className="mb-0">{profileName}</p>
            <div className="d-flex ml-auto">
                {shouldSuccessfulStatusBeVisible && <SuccessfullyLinkedStatusElement />}
                {shouldRelinkButtonBeVisible && (
                    <Button variant="brand" className="font-extrabold mr-1">
                        <Link to={relinkUrl} target="_blank">
                            {t("coachSide.relinkAccountsPage.calendarsBlock.relinkButton.label")}
                        </Link>
                    </Button>
                )}
                {shouldDeactivateButtonBeVisible && (
                    <Button
                        variant={isDisconnectButtonDisabled ? "disabled" : "outline-secondary"}
                        className="ml-1"
                        disabled={isDisconnectButtonDisabled}
                        onClick={onDisconnectButtonClick}>
                        {t("coachSide.relinkAccountsPage.calendarsBlock.deactivateButton.label")}
                    </Button>
                )}
            </div>
        </div>
    )
}

export { CoachRelinkAccountsAccountItem, type TCoachRelinkAccountsAccountItemProps }
