import React, { ReactNode } from "react"

import classNames from "classnames"
import { useTranslation } from "react-i18next"
import { Link, useNavigate } from "react-router-dom"

import { ConfigKeys, INDEX_URL } from "main-app/constants"
import { useStoreContext } from "main-app/context/GlobalStore"
import { UserEnrollModule } from "main-app/models/user"
import ProductTypeLogo from "main-app/shared/ProductTypeLogo"
// particularly unclear issue with lazy-loaded swiper styles; swiper isn't used in Chapter/ at all for now
import "main-app/shared/swiper/styles.scss"

import MobileMenuBurger from "./MobileMenuBurger"

interface IProps {
    renderFooter?(): JSX.Element
    title: string
    module: UserEnrollModule
    children: ReactNode
    renderLeftSidebar?(): React.ReactNode
    renderUserHeaderDropdown?(): React.ReactNode
    className?: string
}

const ChapterLayout: React.FC<IProps> = ({
    children,
    renderFooter: renderFooterContent,
    title = "",
    module,
    renderLeftSidebar,
    renderUserHeaderDropdown,
    className = ""
}) => {
    const { t } = useTranslation()
    const { toggleBurgerMenu, isOpenBurgerMenu, isOpenSidebar, getConfig } = useStoreContext()
    const navigate = useNavigate()
    const showDashboard = getConfig(ConfigKeys.showDashboard)

    if (!showDashboard) {
        navigate(INDEX_URL)
        return null
    }

    return (
        <>
            <MobileMenuBurger />
            {renderLeftSidebar?.()}
            {renderUserHeaderDropdown?.()}
            <div
                className={classNames(
                    "chapter",
                    { [className]: className },
                    isOpenSidebar ? "opened-sidebar" : "closed-sidebar"
                )}>
                <header className="chapter-header">
                    <div className="container">
                        <nav className="chapter-navbar chapter-navbar-expand-lg">
                            <Link to={`/dashboard/${module?.rank}`} className="home-link">
                                <ProductTypeLogo width={26} heigth={26} />
                                <span className="home-link-text">{t("Home")}</span>
                            </Link>
                            <span className="header-title">{title}</span>
                            <button
                                className={classNames("menu-hamburger ml-0", { open: isOpenBurgerMenu })}
                                onClick={toggleBurgerMenu}>
                                <span />
                                <span />
                                <span />
                            </button>
                            <div className="" id="navbar" />
                        </nav>
                    </div>
                </header>
                <main className="chapter-main d-flex pt-3 justify-content-center">{children}</main>
                {renderFooterContent && (
                    <footer className="chapter-footer footer--gray sticky-bottom-lg">{renderFooterContent()}</footer>
                )}
            </div>
        </>
    )
}

export { type IProps as IChapterLayoutProps }

export default ChapterLayout
