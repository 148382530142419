import React, { MutableRefObject, useEffect, useMemo } from "react"

import classNames from "classnames"
import { useTranslation } from "react-i18next"

import useMediaQuery from "common/hooks/use-media-query"
import useDisableBodyScroll from "common/hooks/user-disable-body-scroll"

import { MAX_WIDTH_MOBILE_MEDIA } from "main-app/constants"
import { useStoreContext } from "main-app/context/GlobalStore"
import { SideMenuClose } from "main-app/svgs/SideMenuCloseSvg"
import SideMenuSvg from "main-app/svgs/SideMenuSvg"

import Button from "../button/Button"

import "./styles.scss"

interface IProps {
    className?: string
    sidebarLogo?: React.ReactNode
    mobileLogo?: React.ReactNode
    closedMenu?: boolean
    children: React.ReactNode
    sidebarTogglerButtonRef?: MutableRefObject<HTMLButtonElement>
    tag?: "div" | "aside"
}

export const SIDEBAR_MENU_TOGGLER_ID = "sidebar-menu-toggler"

const Sidebar: React.FC<IProps> = ({
    children,
    sidebarLogo,
    mobileLogo,
    className = "",
    closedMenu = false,
    sidebarTogglerButtonRef,
    tag: Tag = "div"
}) => {
    const { isOpenBurgerMenu, isOpenSidebar, toggleSidebar } = useStoreContext()
    const matches = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA)
    const { t } = useTranslation()

    useEffect(() => {
        if (isOpenSidebar && closedMenu) {
            toggleSidebar()
        }
    }, [closedMenu])

    const sideBarStyles = useMemo<React.CSSProperties>(() => {
        if (matches && isOpenBurgerMenu) {
            return {
                display: "flex"
            }
        }

        if (matches) {
            return {
                display: "none"
            }
        }

        return {
            display: "flex"
        }
    }, [isOpenBurgerMenu, matches])

    useDisableBodyScroll(isOpenBurgerMenu && isOpenBurgerMenu)

    return (
        <Tag
            style={sideBarStyles}
            className={classNames("width-vertical__menu", {
                [className]: className,
                closed: !matches && !isOpenSidebar
            })}>
            <div className="h-100">
                <div id="sidebar-menu" className="sidebar-menu">
                    {sidebarLogo ? (
                        <span aria-label="menu logo" className="sidebar-menu__logo">
                            {sidebarLogo}
                        </span>
                    ) : null}
                    {mobileLogo ? <div className="logo-left__mobile">{mobileLogo}</div> : null}
                    {!matches && isOpenSidebar ? (
                        <Button
                            ref={sidebarTogglerButtonRef}
                            variant="default"
                            className="p-0 mt-30"
                            onClick={toggleSidebar}
                            aria-label={t("Close site bar menu")}>
                            <SideMenuClose />
                        </Button>
                    ) : null}
                    {isOpenSidebar || isOpenBurgerMenu ? children : null}
                    {!matches && !isOpenSidebar ? (
                        <Button
                            variant="default"
                            className="p-0 mt-30"
                            onClick={toggleSidebar}
                            aria-label={t("Open site bar menu")}>
                            <SideMenuSvg />
                        </Button>
                    ) : null}
                </div>
            </div>
        </Tag>
    )
}
export default Sidebar
