import React, { useEffect, useState } from "react"

import classNames from "classnames"
import { useTranslation } from "react-i18next"
import { CSSTransition, TransitionGroup } from "react-transition-group"

import Heading from "common/components/Heading/Heading"
import { isNullOrUndefined } from "common/utils/gates"
import { getErrorMessages } from "common/utils/get-error-messages"

import { ConfigKeys, SessionScheduleStatus } from "main-app/constants"
import { type IAuthContext, useAuthContext } from "main-app/context/Auth"
import { useStoreContext } from "main-app/context/GlobalStore"
import CoachBlock from "main-app/elements/coach-info/CoachBlock"
import CoachTimeScheduleModal from "main-app/elements/coach-time-schedule-modal"
import {
    PairingQuestionAnswerOption,
    usePairingQuestions,
    useSubmitPairingQuestion
} from "main-app/entities/pairing-question"
import Coach from "main-app/models/coach"
import Button from "main-app/shared/button/Button"
import { CheckMarkAttendee } from "main-app/svgs/CheckMarkAttendee"
import useNavigationOnboarding from "main-app/utils/hooks/use-navigation-onboarding"

import useNextStep from "./api/mutations/use-next-step"
import useAvailableCoaches from "./api/use-available-coaches"
import PairingQuestionView from "./components/PairingQuestionView"
import { StepsNavigator } from "./components/StepsNavigator"
import { WarningMessage } from "./components/WarningMessage"
import { COACH_BLOCK_TRANSITION_DELAY, OnboardingSteps } from "./constants"
import OnboardingLayout from "./layout/OnboardingLayout"

const CoachSelectStep = () => {
    const { t } = useTranslation()
    const { getConfig } = useStoreContext()
    const { user }: IAuthContext = useAuthContext()
    const handleNext = useNextStep()
    const { data: pairing } = usePairingQuestions()
    const submitPairingQuestion = useSubmitPairingQuestion()
    const { getNextStepUrl, refetchSteps, onboardingSteps } = useNavigationOnboarding()

    const [showCoachScheduleModal, setShowCoachScheduleModal] = useState(false)
    const [selectedCoach, setSelectedCoach] = useState<Coach | null>(null)
    const [error, setError] = useState(null)
    const [isPairingQuestionView, setIsPairingQuestionView] = useState(!user?.coachMatchingFinished)
    const { data } = useAvailableCoaches({ enabled: !isPairingQuestionView })

    const shouldSkipCoachMatching = onboardingSteps?.session?.skip_coach_matching

    useEffect(() => {
        if (shouldSkipCoachMatching) {
            setIsPairingQuestionView(false)
        }
    }, [shouldSkipCoachMatching])

    const onTimePick = notification =>
        handleNext.mutateAsync({
            step: OnboardingSteps.SESSION_HOLD_TIMES,
            step_data: {
                session_datetime: notification.slot.start,
                coach: selectedCoach.coachId,
                tz: notification.tzid
            }
        })

    const onClickCoachSchedule = (coach: Coach) => {
        refetchSteps()
        setShowCoachScheduleModal(true)
        setSelectedCoach(coach)
    }

    const onCloseCoachScheduleModal = (bookedStatus: SessionScheduleStatus) => {
        setShowCoachScheduleModal(false)

        if (bookedStatus === SessionScheduleStatus.SCHEDULED) {
            getNextStepUrl()
        }
    }

    const hasEnrolledModules = getConfig(ConfigKeys.hasEnrolledModules)

    const getScorePoint = (options: PairingQuestionAnswerOption[], optionUuid: string) => {
        if (isNullOrUndefined(optionUuid)) {
            return 0
        }

        const optionIndex = options.findIndex(option => option.uuid === optionUuid)
        return optionIndex + 1
    }

    const getResponseData = (options: PairingQuestionAnswerOption[]) => {
        return options.reduce((responses, option, index) => {
            responses[index + 1] = {
                option_text: option.optionText
            }

            return responses
        }, {})
    }

    const onSubmitPairingQuestions = formData => {
        const pairingQuestionsPromiseData = pairing.map(item =>
            submitPairingQuestion.mutateAsync({
                question: item.id,
                participant: user.id,
                response_data: getResponseData(item.answerOptions.options),
                score: getScorePoint(item.answerOptions.options, formData[item.uuid])
            })
        )

        Promise.all(pairingQuestionsPromiseData)
            .then(() => {
                window.scrollTo({ top: 0, behavior: "smooth" })
                setIsPairingQuestionView(false)
            })
            .catch(e => setError(getErrorMessages(e)))
    }

    return (
        <OnboardingLayout header={<StepsNavigator activeStep={{ id: OnboardingSteps.SESSION_HOLD_TIMES, order: 6 }} />}>
            <div className="container container-max-md">
                <div className={classNames("mt-6 text-center", isPairingQuestionView ? "mb-50" : "mb-4")}>
                    <Heading textAlign="center">
                        {data?.length === 1 ? t("Meet Your Coach") : t("Select Your Coach")}
                    </Heading>
                    {hasEnrolledModules && (
                        <p className="color-gray font-sm mb-1">
                            {t(
                                "You will have your Onboarding Session, and then start your first learning module the following week."
                            )}
                        </p>
                    )}

                    <p className="color-gray font-sm">
                        {hasEnrolledModules && " "}

                        {isPairingQuestionView
                            ? t("Answer the following questions to get matched with a compatible coach.")
                            : t("Our coaches are hand-selected and represent the best of the best.")}
                    </p>
                </div>
                <div className="container-coach-select">
                    <CSSTransition
                        in={!isPairingQuestionView && !shouldSkipCoachMatching}
                        timeout={1400}
                        classNames="response-submit-animation"
                        onEnter={() => (document.body.style.overflow = "hidden")}
                        onEntered={() => (document.body.style.overflow = "auto")}
                        unmountOnExit>
                        <div className="coach-select-responses-submitted mb-40">
                            <p className="mb-0 text-center d-flex align-items-center justify-content-center">
                                <CheckMarkAttendee type="marked" width={32} height={32} />{" "}
                                <span className="color-gray font-extrabold ml-2">{t("Responses Submitted")}</span>
                            </p>
                        </div>
                    </CSSTransition>

                    <WarningMessage message={error} />
                    <CSSTransition
                        in={isPairingQuestionView && !shouldSkipCoachMatching}
                        timeout={600}
                        classNames="pairing-question-view"
                        unmountOnExit>
                        <PairingQuestionView questions={pairing} onSubmit={onSubmitPairingQuestions} />
                    </CSSTransition>
                    <TransitionGroup className="coach-block-group">
                        {data?.map((coach, index) => (
                            <CSSTransition
                                key={coach.id}
                                timeout={1600}
                                style={{
                                    transitionDelay: `${index * COACH_BLOCK_TRANSITION_DELAY}ms`
                                }}
                                classNames="coach-block-item-animation"
                                unmountOnExit>
                                <CoachBlock
                                    coach={coach}
                                    className="mb-4"
                                    actionBtn={
                                        <Button onClick={() => onClickCoachSchedule(coach)}>{t("Schedule")}</Button>
                                    }
                                />
                            </CSSTransition>
                        ))}
                    </TransitionGroup>
                </div>
            </div>
            <CoachTimeScheduleModal
                show={showCoachScheduleModal}
                onClose={onCloseCoachScheduleModal}
                coach={selectedCoach}
                timePickRequest={onTimePick}
                key={selectedCoach?.coachId}
                session={onboardingSteps?.session}
                showManagerInfoBlock={onboardingSteps?.session?.is_three_way_session}
                scheduledSession={{ session_id: onboardingSteps?.session?.id }}
                sessionId={onboardingSteps?.session?.id}
            />
        </OnboardingLayout>
    )
}

export default CoachSelectStep
