import React, { useEffect, useState } from "react"

import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import useMediaQuery from "common/hooks/use-media-query"

import { MAX_WIDTH_MOBILE_MEDIA, ParticipantUrls } from "main-app/constants"
import { ELocalStorageServiceItemKey, StorageService } from "main-app/services"
import Button from "main-app/shared/button/Button"
import { HappyOwlSvg } from "main-app/svgs/HappyOwlSvg"

import "./styles.scss"

const storageService: StorageService = new StorageService()

export const SimulabBanner = () => {
    const { t } = useTranslation()
    const [active, setActive] = useState(false)
    const isMobile = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA)

    useEffect(() => {
        if (isMobile) {
            setActive(false)
            return
        }
        setActive(storageService.getItem<boolean>(ELocalStorageServiceItemKey.SimulabBannerVisible))
    }, [isMobile])

    const onClick = () => {
        storageService.setItem<boolean>(ELocalStorageServiceItemKey.SimulabBannerVisible, !active)
        setActive(!active)
    }

    return (
        <div className="simulab-banner-wrapper">
            <HappyOwlSvg
                width={active && !isMobile ? 80 : isMobile ? 50 : 70}
                height={active ? 60 : 40}
                className="simulab-banner-icon"
            />

            <div className={`simulab-banner ${active ? "active" : ""}`} onClick={onClick} role="button">
                <p className={`${active ? "fs-24" : "fs-14"} mb-0 cursor-pointer font-extrabold`}>
                    {active
                        ? `✨ ${t("Have you given the SimuLab a try?")} ✨`
                        : `${t("Have you given the ✨ SimuLab ✨ a try?")}`}
                </p>
                {active && (
                    <div>
                        <p className="fs-14">
                            {t(
                                "We recommend having at least two SimuLab conversations per month. These conversations give you an opportunity to practice asking powerful questions in the D.I.G. model, while receiving continuous feedback from your Owl Mentor."
                            )}
                        </p>

                        <Link to={ParticipantUrls.COACHEES} className="d-block mb-1">
                            <Button onClick={onClick} className="fs-14 font-extrabold">
                                {t("Visit SimuLab")}
                            </Button>
                        </Link>

                        <Button
                            variant="default"
                            className="p-0 fs-14 button-underline-hover font-extrabold"
                            onClick={onClick}>
                            {t("Not now")}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    )
}
