import type { AxiosResponse } from "axios"

import { http } from "common/http"

import Urls from "main-app/api/urls"

import type {
    ICoachSessionWithoutAttendanceMarksDto as IDto,
    ICoachSessionWithoutAttendanceMarksModel as IModel
} from "./types"

async function fetchCoachSessionsWithoutAttendanceMarks(): Promise<IDto[]> {
    const { data }: AxiosResponse<IDto[]> = await http.get(Urls.getCoachSessionsWithoutAttendanceMarks())

    return data
}

function adaptCoachSessionsWithoutAttendanceMarks(objects: IDto[]): IModel[] {
    return objects?.map(
        ({ session_id, session_time, session_type, session_modality, title, duration, photo }: IDto): IModel => ({
            sessionId: session_id,
            sessionTime: session_time,
            sessionType: session_type,
            sessionModality: session_modality,
            title,
            duration,
            photo
        })
    )
}

type TCoachDashboardApiUtils = {
    fetchCoachSessionsWithoutAttendanceMarks(): Promise<IDto[]>
    adaptCoachSessionsWithoutAttendanceMarks(objects: IDto[]): IModel[]
}

const coachDashboardApiUtils: TCoachDashboardApiUtils = {
    fetchCoachSessionsWithoutAttendanceMarks,
    adaptCoachSessionsWithoutAttendanceMarks
}

export { coachDashboardApiUtils, type TCoachDashboardApiUtils }
