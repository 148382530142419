import React, { useContext, useMemo, useState } from "react"

import { ConfigKey, JS_CONF } from "main-app/constants"
import { Language, State } from "main-app/models/state"

type StoreContexType = State
type StoreActions = {
    toggleBurgerMenu: () => void
    toggleSidebar: () => void
    resetState: () => void
    setLanguage: (lang: Language) => void
    setAppInit: () => void
    setConfig: (configs: Partial<Record<ConfigKey, string | number | boolean>>) => void
    getConfig: (configKey: ConfigKey) => string | number | boolean
}

export const INITIAL_STATE: State = {
    isOpenBurgerMenu: false,
    isOpenSidebar: true,
    lang: JS_CONF.lang ? JS_CONF.lang : "en",
    appInit: false,
    configs: {}
}

const StoreContext = React.createContext(INITIAL_STATE)

const StoreContextActions = React.createContext(null)

export const GlobalStoreContexProvider = ({ children }) => {
    const [state, setState] = useState<State>(INITIAL_STATE)

    const toggleBurgerMenu = () => {
        setState(state => ({
            ...state,
            isOpenBurgerMenu: !state.isOpenBurgerMenu
        }))
    }

    const toggleSidebar = () => {
        setState(state => ({
            ...state,
            isOpenSidebar: !state.isOpenSidebar
        }))
    }

    const setConfig = (configs: Partial<Record<ConfigKey, string | number | boolean>>) => {
        setState(state => ({
            ...state,
            configs: {
                ...state.configs,
                ...configs
            }
        }))
    }

    const getConfig = (configKey: ConfigKey) => {
        return state.configs[configKey]
    }

    const setAppInit = () => {
        setState(state => ({
            ...state,
            appInit: true
        }))
    }

    const resetState = () => {
        setState(INITIAL_STATE)
    }

    const setLanguage = (lang: Language) => {
        setState(state => ({
            ...state,
            lang
        }))
    }

    const storeActions: StoreActions = useMemo(
        () => ({
            toggleBurgerMenu,
            toggleSidebar,
            resetState,
            setLanguage,
            setAppInit,
            setConfig,
            getConfig
        }),
        [getConfig]
    )

    const stateValues: StoreContexType = useMemo(() => state, [state])

    return (
        <StoreContext.Provider value={stateValues}>
            <StoreContextActions.Provider value={storeActions}>{children}</StoreContextActions.Provider>
        </StoreContext.Provider>
    )
}

export interface IStoreContext extends State, StoreActions {}

export const useStoreContext = (): State & StoreActions => {
    const store = useContext(StoreContext)
    const storeActions = useContext(StoreContextActions)

    if (store === undefined || storeActions === undefined) {
        throw new Error("useStoreContext can only be used inside storeContextProvider")
    }

    return {
        ...store,
        ...storeActions
    }
}
